import React, {Component} from 'react'
import APIService from "../../API";

export default class LiveTVSideBarComponent extends Component {
  state = {
    liveTVSidebarValue: undefined,
    isLiveTVSidebarLoading: false,
  }

  componentDidMount() {
    this.fetchWMFDLiveSidebar()
  }

  fetchWMFDLiveSidebar = () => {
    this.setState({isLiveTVSidebarLoading: true})
    fetch(APIService.serverUrl + "/api/public/layoutResource", {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        reference: "wmfdLiveRightSidebar"
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("Fetch Live TV Sidebar failed: ", response.statusText)
        }
      })
      .then(json => {
        if (json) {
          this.setState({liveTVSidebarValue: json.value})
        }
        this.setState({isLiveTVSidebarLoading: false})
      })
  }

  render = () => <div dangerouslySetInnerHTML={{__html: this.state.liveTVSidebarValue}} />
}