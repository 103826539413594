import React, {Component} from 'react';
import HomeLocalNews from './Components/HomeLocalNews.js';
import HomeEvents from './Components/HomeEvents.js';
import HomeSports from './Components/HomeSports.js';
import HomeWatchWMFD from './Components/HomeWatchWMFD.js';

export default class Home extends Component {

  renderGoogleAd12() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-12") })
  }
  renderGoogleAd22() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-22") })
  }

  render() {

    return (
      <div>

        <HomeLocalNews />


          <div className="widget advertisement">
            <div className="ad-holder">
              <div id='div-gpt-ad-1530459263900-12'>
                { this.renderGoogleAd12() }
              </div>
            </div>
          </div>


        <HomeEvents />

        <p>&nbsp;</p>

          <div className="widget advertisement">
            <div className="ad-holder">
              <div id='div-gpt-ad-1530459263900-22'>
                { this.renderGoogleAd22() }
              </div>
            </div>
          </div>

        <HomeSports />

      </div>
    )
  }
}
