import React, {Component} from 'react';
import AD from 'react-google-publisher-tag';


export default class SatRadar extends Component {

  renderGoogleAd0() {
    window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1530459263900-0'); })
  }

  renderGoogleAd1() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-1") })
  }

  renderGoogleAd12() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-12") })
  }

  render() {

    let satRad = this.props.match.params.satrad

    return (
      <div>

        <div className="container" style={{ "text-align":"center", "width":"940px" }}>
          <div className="row" style={{display: "flex"}}>
            <div className="col-md-10" style={{ "text-align":"center", "order": "0", "-webkit-order": "2" }} >
              <div id='div-gpt-ad-1530459263900-12' >
                { this.renderGoogleAd12() }
                <AD path="/20137842/DWMFD_728x90_ROS" />
              </div>
              <iframe
                frameBorder="0"
                title="satradar"
                style={{height:"590px", width:"730px", "text-align":"center"}}
                src={"https://wmfd.intelliweather.net/imagery/wmfd/"+satRad+"_640x480.htm"} />
            </div>
            <div className="col-md-2" style={{ "text-align":"center", "order": "2", "-webkit-order": "0" }}>
              <div id='div-gpt-ad-1530459263900-1' >
                { this.renderGoogleAd1() }
                <AD path="/20137842/DWMFD_120x600_ROS_Flag" />
              </div>
              <p></p>
              <div id='div-gpt-ad-1530459263900-0' >
                { this.renderGoogleAd0() }
                <AD path="/20137842/DWMFD_120x120_ROS_Result_Box" />
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

/*

{ this.renderGoogleAd1() }


*/
