import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Form, Header, Icon, Label, List, Modal, Segment} from "semantic-ui-react";
import APIService from "../API";


class RestaurantDirectory extends Component {

  state = {
    restaurantList: [],
    isFetchingRestaurants: false,

    isRestaurantModalOpen: false,
    selectedRestaurant: {},
  }

  componentDidMount() {
    this.getRestaurants();
  }

  getRestaurants = () => {
    this.setState({isFetchingRestaurants: true});
    fetch(APIService.serverUrl + "/api/public/promotional-restaurants")
      .then( (response) => {
        if (response.ok) {
          return response.json()
        } else {
          return null;
        }
      })
      .then( (json) => {
        if (json) {
          this.setState({restaurantList: json})
        }
        this.setState( {isFetchingRestaurants: false})
      })
  }

  render = () => {
    const selectedRestaurant = this.state.selectedRestaurant;
    const segmentStyle = {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    };
    const linkStyle = {
      display: "block",
      margin: "10px 0px 12px",
    }
    return(
      <div>
        <Header
          as="h1"
          style={{color: "rgb(46, 109, 164)"}}
          content="Local Restaurants Open During COVID-19"
          subheader=""
        />
        <p>Local restaurants are a key part of our community and our economy. COVID-19 has created significant revenue burdens for local restaurants. We complied this list to help you find out where you can get carry-out and delivered food and beverage during this crisis. Please support our local businesses.</p><p>If you are a restaurant and would like your listing for free, please click below.  WMFD TV, Mix 106 WVNO, WRGM ESPN 106.7, 97.3 The Spur, 99.3 The Light and Solsys Inc care about our local community.</p><p>#InThisTogetherOhio</p>
        <Button
          content="Add Your Restaurant"
          color="blue"
          style={{marginBottom: '20px'}}
          onClick={() => {
            this.props.history.push("/small-business-form")
          }}
        />
        <div style={{margin: "10px 0px 28px"}}>
          <Header as='h5' style={{marginBottom: "0px"}} content="Brought to you locally by:" />
          <img src='/images/logo.png' />
          <h3 style={{display: "inline", color: "#555555", marginLeft: "16px", marginRight: "20px"}}>&amp;</h3>
          <a href="https://solsysinc.net" target="_blank" ><img src='/images/solsys-logo-blue.png' height="88px" /></a>
        </div>
        <Label.Group>
          <Label basic>
            <Icon color="green" name="desktop"/>
            Online Ordering
          </Label>
          <Label basic>
            <Icon color="green" name="male"/>
            Pickup
          </Label>
          <Label basic>
            <Icon color="green" name="shipping fast"/>
            Delivery
          </Label>
          <Label basic>
            <Icon color="green" name="car"/>
            Curbside/Drive-thru
          </Label>
        </Label.Group>
        <List divided relaxed>
          {this.state.restaurantList.map((restaurant) => {
            return(
              <List.Item
                onClick={() => {
                  this.setState({
                    isRestaurantModalOpen: true,
                    selectedRestaurant: restaurant,
                  })}}
                  style={{
                    padding: '10px 0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
              >

                <List.Icon
                  name="food"
                  size="large"
                  verticalAlign="middle"
                />
                <List.Content style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <List.Header as="a">{restaurant.name}</List.Header>
                    <List.Description as="a">{restaurant.address}</List.Description>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {!restaurant.hasOnlineOrdering ?
                      null
                      :
                      <Icon color="green" name="desktop" size="large"/>
                    }
                    {!restaurant.isCarryOutOption ?
                      null
                      :
                      <Icon color="green" name="male" size="large"/>
                    }
                    {!restaurant.isDeliveryOption ?
                      null
                      :
                      <Icon color="green" name="shipping fast" size="large"/>
                    }
                    {!restaurant.isCurbsideOption ?
                      null
                      :
                      <Icon color="green" name="car" size="large"/>
                    }
                  </div>
                </List.Content>

              </List.Item>
            )
          })}
        </List>

        <Modal size="tiny" open={this.state.isRestaurantModalOpen}>
          <Modal.Content>
            <Header as='h2' style={{color: "rgb(46, 109, 164)"}} textAlign='center' content={selectedRestaurant.name} />

            <Label.Group>
              {!selectedRestaurant.hasOnlineOrdering ? null :
                <Label size="large" color='green'>
                  <Icon name="desktop" inverted/>
                  Online Ordering
                </Label>
              }
              {!selectedRestaurant.onlineOrderingNote ? null :
                <Label basic color='blue' pointing='left'>
                  {selectedRestaurant.onlineOrderingNote}
                </Label>
              }
              {!selectedRestaurant.isCarryOutOption ? null :
                <Label size="large" color='green'>
                  <Icon name="male" inverted/>
                  Carryout
                </Label>
              }
              {!selectedRestaurant.isCurbsideOption ? null :
                <Label size="large" color='green'>
                  <Icon name="car" inverted/>
                  Curbside/Drive-thru
                </Label>
              }
            </Label.Group>
            {!selectedRestaurant.isDeliveryOption ? null :
              <Label size="large" color='green'>
                <Icon name="shipping fast" inverted/>
                Delivery
              </Label>
            }
            {!selectedRestaurant.deliveryNote ? null :
              <Label basic color='blue' pointing='left'>
                {selectedRestaurant.deliveryNote}
              </Label>
            }

            <Header sub content="Description:" />
            <Form>
              <Form.TextArea readOnly>{selectedRestaurant.description}</Form.TextArea>
            </Form>

            <Header sub content="Location:" />
            <a style={linkStyle}>
              <Header icon='map marker outline' content={selectedRestaurant.address} />
            </a>

            <Header sub content="Hours:" />
            <a style={linkStyle}>
              <Header icon='clock outline' content={selectedRestaurant.hours} />
            </a>

            <Header sub content="Phone:" />
            <a style={linkStyle} href={"tel:" + selectedRestaurant.phoneNumber}>
              <Header icon='phone' content={selectedRestaurant.phoneNumber} />
            </a>

            <Header sub content="Website:" />
            <a style={linkStyle} href={selectedRestaurant.website} target="_blank">
              <Header icon='globe' content={selectedRestaurant.website} />
            </a>

            <Button
              content="Back to List"
              color="blue"
              onClick={() => {
                this.setState({isRestaurantModalOpen: false})
              }}
              style={{marginTop: '15px'}}
            />
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

export default withRouter(RestaurantDirectory);
