
let APIService = {
  local: "http://api.wmfd.solsys.local:8083",
  testing: "http://api.wmfd.testing.solsys.local:8083",
  staging: "https://wmfdapi.solsyshosting.com",
  production: "https://api.wmfd.com",
};

APIService.serverUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";
APIService.apiService = process.env.REACT_APP_API_SERVICE || "RUNTIME_REPLACE_REACT_APP_API_SERVICE";

module.exports = APIService;

/*

Change the API to the proper URI here.

*/
