import React, {Component} from 'react';

export default class Footer extends Component {

  render() {

    return (

      <div>
        <footer id = "footer" className = "footer">
          <div className = "footer-three">
            <div className = "container">
              <div className = "row">
                <div className = "col-md-6">
                  <div className = "footer-logo">
                    <img src = "/images/logo.png" alt = "" />
                  </div>
                </div>
                <div className="col-md-6">
                  <ul className="footer-social">
                    <li><a href="https://www.twitter.com/wmfdtv"><i className="fa fa-twitter"></i></a></li>
                    <li><a href="https://www.facebook.com/wmfdtv"><i className="fa fa-facebook"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-four">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <p>All Rights Reserved 2021 © Mid-State Television Inc.</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

    )
  }
}
