import React, {Component} from 'react';
import APIService from "../API";

export default class BusinessRewards extends Component {

  state = {
    businessRewardsState: null,
  };

  componentDidMount() {
    this.fetchLayoutResource('businessRewardsClub');
  }

  fetchLayoutResource = (type) => {
    fetch(APIService.serverUrl + "/api/public/layoutResource", {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        reference: type,
      })
    })
    .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({ businessRewardsState: json.value })
      } else {
        console.log("Fetch "+type+" Resource failed")
      }
    })
  };


  render() {
    return (

      <div class="cp-news-grid-style-1">
        <div class="section-title blue-border">
          <h2>Business reward Club</h2>
          <small>Mix 106.1 FM Business Reward Club </small>
        </div>
        <div class="row">
          <ul class="grid">

            <div dangerouslySetInnerHTML={{__html: this.state.businessRewardsState}} />

          </ul>
        </div>
      </div>

    )
  }
}

/*



*/
