import React, {Component} from 'react';
import LatestVideo from './Components/LatestVideo.js';

export default class ContestRules extends Component {


  render() {
    return (
      <div className="cp-news-grid-style-1">
        <div className="section-title blue-border">
          <h2>Contest Rules</h2>
          <small>for WVNO FM, WRGM AM, WMFD TV, WOHZ TV and its affilitates</small>
        </div>
        <div className="col-md-12 row">
          <ul className="grid">

          <p>Here are the contest rules for promotions on WMFD TV, WVNO FM, WRGM AM, WMFD TV and its affiliates. Prize winners will be notified by station personnel. All prizes must be picked up within 30 days of being awarded. All prizes not picked up within 30 days will become the property of this radio station. By accepting a prize, the winner agrees to the use of name, likeness and personal information, without further compensation. In fairness to all of our listeners, once you have won a prize, you may not win again for 30 days. Employees of Mid-State Multimedia, contest sponsors and their families are not eligible for contests. Only one winner per household, per contest. Each contest is subject to its own set of rules applicable to that contest and disclosed on the contest page. You must be 18 year or older to enter, unless otherwise specified. For a written list of rules, call 419-529-5900 or write mid-state multimedia at 2900 Park Avenue West, Mansfield Ohio, 44906. </p>

          </ul>
          <div id='widget'>
            <a
              href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com/haring-realty-home-show&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%20haring-realty-home-show&amp;tw_p=tweetbutton&amp;url=https://wmfd.com/haring-realty-home-show'}
              target="_blank" rel="noopener noreferrer"
            >
              <span
                className="btn"
                style={{
                  background:"#1DA1F2",
                  borderRadius:"5",
                  color:"#fff",
                  fontSize:"11px",
                  fontWeight:"bold",
                  padding:"2px 5px 1px 5px",
                }}>
                  <i className="fa fa-twitter" /> Tweet
              </span>
            </a>
            &nbsp;&nbsp;
            <div
              className="fb-share-button"
              data-href={'https://wmfd.com/haring-realty-home-show'}
              data-layout="button_count"
            />
          </div>
          <p>&nbsp;</p>
        </div>
        {/*<div className="row">
          <ul className="grid">
            <li className="col-md-6 col-sm-6">
              <div className="cp-news-post-excerpt">
                <div className="cp-thumb"></div>
                <div className="cp-post-content">
                  <ul className="cp-post-tools"></ul>
                </div>
              </div>
            </li>
          </ul>
        </div>*/}

        {/*<LatestVideo />*/}

      </div>
    )
  }
}

/*



*/
