import React, {Component} from 'react';
import ReactJWPlayer from 'react-jw-player';
import LatestVideo from './Components/LatestVideo.js';
// import APIService from "../API";

export default class Advertise extends Component {


  render() {
    return (
      <div className="cp-news-grid-style-1" >
        <div className="section-title blue-border">
          <h2>Advertise With Us</h2>
          <small>Use Radio, TV and Digital to increase sales!</small>
        </div>
        <div className="col-md-12 row">
          <ul className="grid">

          {/* Insert about pages content between these comments */}

          {/* https://content.jwplatform.com/players/6ztNTQuR-WWY0MVpB.js */}
          <ReactJWPlayer
            licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
            playlist={'//content.jwplatform.com/v2/media/6ztNTQuR'}
            playerId='WWY0MVpB'
            playerScript={'https://content.jwplatform.com/players/6ztNTQuR-WWY0MVpB.js'}
            aspectRatio='16:9'
            customProps={{
              width: '100%',
              displaytitle: false,
              advertising: {
                "admessage": "This video will resume in xx seconds",
                "adscheduleid": "s1OFE6R4",
                "client": "googima",
                "schedule": [
                  {
                    "offset": "pre",
                    "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                    "type": "linear"
                  }
                ]
              }
            }}
          />
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>Let our team of advertising professionals help your business grow. Contact us to get started!</p>
          <p>Email: comments@wmfd.com</p>
          <p>Call: 419-529-5900 ext. 0</p>
          <p><img src="/logos/mmg.jpg" alt="mmg" /></p>

          {/* Insert about pages content between these comments */}

          </ul>
          <div id='widget'>
            <a
              href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com/haring-realty-home-show&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%20haring-realty-home-show&amp;tw_p=tweetbutton&amp;url=https://wmfd.com/haring-realty-home-show'}
              target="_blank" rel="noopener noreferrer"
            >
              <span
                className="btn"
                style={{
                  background:"#1DA1F2",
                  borderRadius:"5",
                  color:"#fff",
                  fontSize:"11px",
                  fontWeight:"bold",
                  padding:"2px 5px 1px 5px",
                }}>
                  <i className="fa fa-twitter" /> Tweet
              </span>
            </a>
            &nbsp;&nbsp;
            <div
              className="fb-share-button"
              data-href={'https://wmfd.com/haring-realty-home-show'}
              data-layout="button_count"
            />
          </div>
          <p>&nbsp; </p>
        </div>
        {/*<div className="row">
          <ul className="grid">
            <li className="col-md-6 col-sm-6">
              <div className="cp-news-post-excerpt">
                <div className="cp-thumb">
                </div>
                <div className="cp-post-content">
                  <ul className="cp-post-tools">
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>*/}

        {/*<LatestVideo />*/}

      </div>
    )
  }
}

/*



*/
