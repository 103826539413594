import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

class LocalSportsTVBanner extends Component {
  render = () => {
    return (
      <div style={{backgroundColor: '#6e6e6e'}}>
        <a href="https://www.wmfdsports.com">

          <div id="sportsMobileBanner">
              <img
                id="wmfdSportsImg"
                src="/graphics/localSportsBanner/logo-wmfd-sports-2x.png"
              />
              <img
                id="schoolCluster"
                src="/graphics/localSportsBanner/mobileStreaming2021WebsiteHeader.png"
              />
          </div>

          <div id="sportsDesktopBanner">

            <img
              src="/graphics/localSportsBanner/logo-wmfd-sports-2x.png"
              style={{width: '90px', height: '37px', marginTop: 'auto', marginBottom: 'auto'}}
            />

            <div style={{height: '45px', display: 'flex'}}>
              <img src="/graphics/localSportsBanner/mansfield.png" style={{width: '80px'}}/>
              <img src="/graphics/localSportsBanner/ontario.png" style={{width: '80px'}}/>
              <img src="/graphics/localSportsBanner/crawford.png" style={{width: '80px'}}/>
              <img src="/graphics/localSportsBanner/lexington.png" style={{width: '80px'}}/>
              <img src="/graphics/localSportsBanner/galion.png" style={{width: '80px'}}/>
              <img src="/graphics/localSportsBanner/shelby.png" style={{width: '80px'}}/>
              <img src="/graphics/localSportsBanner/bucyrus.png" style={{width: '80px'}}/>
              <img src="/graphics/localSportsBanner/streaming-generic.png" style={{width: '80px', marginLeft: '8px'}}/>
            </div>

          </div>
        </a>
      </div>
    );
  };
};

export default withRouter(LocalSportsTVBanner);
