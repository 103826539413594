import React, {Component} from 'react';
import LatestVideo from './Components/LatestVideo.js';
import AD from 'react-google-publisher-tag';


export default class Weather extends Component {

  render() {
    return (
      <div>

        <div className="cp-news-grid-style-1">
          <div className="section-title blue-border">
            <h2>Local Weather</h2>
            <small>Local Radars, Maps and Area Forecasts from WMFD TV</small>
          </div>
          <div className="row">
            <ul className="grid">
              <li className="col-md-6 col-sm-6">
                <div className="cp-news-post-excerpt">
                  <div className="cp-thumb">
                    <a
                      href="/weather/satrad_nat"
                      target="popup"
                      onClick={() => window.open("/weather/satrad_nat", 'popup', 'width=1024,height=800')} >
                      <img src="https://wmfd.intelliweather.net/imagery/wmfd/satrad_nat_640x480_12.jpg" alt="WMFD Doppler Radar" />
                    </a>
                  </div>
                  <div className="cp-post-content">
                    <div className="catname">
                      <a
                        href="/weather/satrad_nat"
                        target="popup"
                        className="catname-btn btn-purple waves-effect waves-button"
                        onClick={() => window.open("/weather/satrad_nat", 'popup', 'width=1024,height=800')}
                        >National Radar</a>
                    </div>
                    <ul className="cp-post-tools">
                      <li><i className="icon-4"></i> National Radar - Click to animate</li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="col-md-6 col-sm-6">
                <div className="cp-news-post-excerpt">
                  <div className="cp-thumb">
                    <a
                      target="popup"
                      href="/weather/satrad_reg"
                      onClick={() => window.open("/weather/satrad_reg", 'popup', 'width=1024,height=800')} >
                      <img src="https://wmfd.intelliweather.net/imagery/wmfd/satrad_reg_640x480_12.jpg" alt="WMFD Doppler Radar" />
                    </a>
                  </div>
                  <div className="cp-post-content">
                    <div className="catname">
                      <a
                        target="popup"
                        href="/weather/satrad_reg"
                        className="catname-btn btn-pink waves-effect waves-button"
                        onClick={() => window.open("/weather/satrad_reg", 'popup', 'width=1024,height=800')}
                        >Regional</a>
                    </div>
                    <ul className="cp-post-tools">
                      <li><i className="icon-4"></i> Regional Radar - Click to animate</li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="col-md-6 col-sm-6">
                <div className="cp-news-post-excerpt">
                  <div className="cp-thumb">
                    <a
                      target="popup"
                      href="/weather/rad_oh_mansfield"
                      onClick={() => window.open("/weather/rad_oh_mansfield", 'popup', 'width=1024,height=800')} >
                      <img src="https://wmfd.intelliweather.net/imagery/wmfd/rad_oh_mansfield_640x480.jpg" alt="WMFD Doppler Radar" />
                    </a>
                  </div>
                  <div className="cp-post-content">
                    <div className="catname">
                      <a
                        target="popup"
                        href="/weather/rad_oh_mansfield"
                        className="catname-btn btn-purple waves-effect waves-button"
                        onClick={() => window.open("/weather/rad_oh_mansfield", 'popup', 'width=1024,height=800')}
                        >Local Radar </a>
                    </div>
                    <ul className="cp-post-tools">
                      <li><i className="icon-4"></i> Local Radar - Click to animate</li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="col-md-6 col-sm-6">
                <div className="cp-news-post-excerpt">
                  <div className="cp-thumb">
                    <img src="https://wmfd.intelliweather.net/imagery/wmfd/warnmap_oh_mansfield_640x480.jpg" alt="WMFD Doppler Radar" />
                  </div>
                  <div className="cp-post-content">
                    <div className="catname">
                      <a id="ww" href="#ww" className="catname-btn btn-purple waves-effect waves-button">Watches & Warnings </a>
                    </div>
                    <ul className="cp-post-tools"></ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div id='div-gpt-ad-1530459263900-12'>
          <AD path="/20137842/DWMFD_728x90_ROS" />
        </div>
        <p></p>
        <iframe src="https://builder.zoomradar.net/weather_builder/weather.page.v4.php?fw=280&fh=840&z=44901&ft=Your%20Extended%20Forecast&ar=50&l=english&lo=wmfd.png&ae=1" width="100%" height="840" title="iframe"></iframe>
        <p></p>
        <div id='div-gpt-ad-1530459263900-12'>
          <AD path="/20137842/DWMFD_728x90_ROS" />
        </div>
        <p></p>
        <iframe src="https://builder.zoomradar.net/zoomradar_weather_map/weather_page.php?key=WjBgVfFbOHitIa9" width="100%" height="480" frameBorder="0" scrolling="no" title="iframe"></iframe>
        <p></p>

        {/*<LatestVideo />*/}

      </div>
    )
  }
}
