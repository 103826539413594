import React, {Component} from 'react';
import LatestVideo from './Components/LatestVideo.js';

export default class ChurchServices extends Component {

  renderSoundCloudOne() {
    return { __html:
      `<iframe scrolling="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/159434223&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false" width="100%" height="366" frameborder="no"></iframe>

      <iframe scrolling="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/159435042&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false" width="100%" height="366" frameborder="no"></iframe>

      <iframe scrolling="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/159433214&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false" width="100%" height="366" frameborder="no"></iframe>

      <iframe scrolling="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/159433836&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false" width="100%" height="366" frameborder="no"></iframe>

      <iframe scrolling="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/159434731&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false" width="100%" height="366" frameborder="no"></iframe>`

    };
  }

  render() {
    return (
      <div className="cp-news-grid-style-1">
        <div className="section-title blue-border">
          <h2>Church Services from 99.3 The Light</h2>
          <small>WMFD Online in HD on your schedule!</small>
        </div>
        <div className="col-md-12 row">
          <ul className="grid">

            <div dangerouslySetInnerHTML={this.renderSoundCloudOne()} />

            <p>&nbsp;</p>
          </ul>
          <div id='widget'>
            <a
              href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com/church-services&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%20church-services&amp;tw_p=tweetbutton&amp;url=https://wmfd.com/church-services'}
              target="_blank" rel="noopener noreferrer"
            >
              <span
                className="btn"
                style={{
                  background:"#1DA1F2",
                  borderRadius:"5",
                  color:"#fff",
                  fontSize:"11px",
                  fontWeight:"bold",
                  padding:"2px 5px 1px 5px",
                }}>
                  <i className="fa fa-twitter" /> Tweet
              </span>
            </a>
            &nbsp;&nbsp;
            <div
              className="fb-share-button"
              data-href={'https://wmfd.com/church-services'}
              data-layout="button_count"
            />
          </div>
          <p>&nbsp;</p>
        </div>

        {/*<LatestVideo />*/}

      </div>
    )
  }
}

/*

*/
