import React, {Component} from 'react';
// import MoreArticles from './Components/MoreArticles.js';
import moment from 'moment';


export default class Pollen extends Component {

  state= {
    id: '77063',
    type: { id: 1, name: 'Local News' },
  }

  render() {

    let today = moment().format('LL')

    return (

      <div>

        <div className="cp-news-post-excerpt">
          <div className="cp-thumb">
            <img src="/graphics/750x422/allergyreport5day.jpg" alt="WMFD.com Allergy Report" />
          </div>
          <div className=" cp-post-content">
            <h2><a href="/pollen">5 Day Pollen Forecast</a></h2>
            <ul className="cp-post-tools">
              <li><i className="icon-1"></i> {today}</li>
              <li><i className="icon-2"></i> WMFD Staff</li>
              <li><i className="icon-3"></i> Local Pollen</li>
            </ul>
          </div>
          <div className="cp-sidebar-content">
            <p>Here is a look at the 5 Day Pollen Forecast. You can also signup to receive pollen alerts to your cell. To signup click texting at the top of the page.</p><p>Brought to you by: Bill Harris Auto and Midwest Allergy and Asthma.</p>
            <p>&nbsp;</p>
          </div>
        </div>

        { /* <MoreArticles type={ this.state.type.name } id={ this.state.id } push={this.props.history.push }/> */ }

      </div>

    )
  }
}
