import React, {Component} from 'react';
// import ReactJWPlayer from 'react-jw-player';
import MoreArticles from './Components/MoreArticles.js';
// import APIService from "../API";


export default class ClosedCaptioning extends Component {

  state = {
    article: {
      id: 0,
      articleTypes: [
        { id: 3, name: 'News',}
      ],
    },
    isArticleLoading: false,
  };


  render() {
    return (
      <div className="cp-news-grid-style-1">
        <div className="row">
          <ul className="grid">

          {/* Insert about pages content between these comments */}
            <div className="col-md-12">
              <div className="cp-post-content">
                <h2><a id="cc" href="#cc">Closed Captioning</a></h2>
                <ul className="cp-post-tools">
                  <li><i className="icon-3"></i> WMFD-TV / WOHZ-CD</li>
                </ul>
              </div>
              <div className="cp-sidebar-content">
                If you have any questions or concerns about closed captioning on WMFD TV or WOHZ CD. You may contact us the following ways:<br /><br />
                WMFD TV / WOHZ CD Attn: Jeromy Bolen <br />
                Closed Captioning 2900 Park Ave. W. <br />
                Mansfield, OH 44906 <br /><br />
                You may also call (419) 529-5900 ext 227 or email at comments @ wmfd.com<br />
                <p>&nbsp;</p>
              </div>
            </div>

          {/* Insert about pages content between these comments */}

          </ul>
          <div className="col-md-8" id='widget'>
            <a
              href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com/haring-realty-home-show&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%20haring-realty-home-show&amp;tw_p=tweetbutton&amp;url=https://wmfd.com/haring-realty-home-show'}
              target="_blank" rel="noopener noreferrer"
            >
              <span
                className="btn"
                style={{
                  background:"#1DA1F2",
                  borderRadius:"5",
                  color:"#fff",
                  fontSize:"11px",
                  fontWeight:"bold",
                  padding:"2px 5px 1px 5px",
                }}>
                  <i className="fa fa-twitter" /> Tweet
              </span>
            </a>
            &nbsp;&nbsp;
            <div
              className="fb-share-button"
              data-href={'https://wmfd.com/haring-realty-home-show'}
              data-layout="button_count"
            />
          </div>
        </div>
        <p>&nbsp;</p>

        {!this.state.isArticleLoading
          ? <MoreArticles
              articleType={ this.state.article.articleTypes.length ? this.state.article.articleTypes[0] : undefined }
              articleId={ this.state.article.id }
              push={this.props.history.push }
            />
          : null
        }

      </div>
    )
  }
}

/*



*/
