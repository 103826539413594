import React, {Component} from 'react';
import MoreArticles from './Components/MoreArticles.js';
// import ReactJWPlayer from 'react-jw-player';
// import HomeEvents from './Components/HomeEvents'
// import { Route } from "react-router-dom";
// import APIService from '../API.js';
// import Moment from 'react-moment';
import 'moment-timezone';
// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import Helpers from "./Helpers";

export default class EmergencyLevels extends Component {

  state = {
    article: {},
    // isArticleLoading: true,
  };

  currentPath = this.props.location.pathname;

  componentDidMount() {
    // this.getData();
  }


  render() {
    return (

      <div>
        <div className="cp-news-post-excerpt">
          <div className=" cp-post-content">
            <p/>
            <h1
              onClick={() => this.props.history.push(this.currentPath)}
              style={{ color:'#2e6da4', cursor:'pointer' }}
            >Emergency Snow Levels
            </h1>

            <ul className="cp-post-tools">
              <li><i className="icon-2"/> WMFD Staff</li>
              <li><i className="icon-3"/> Local News</li>
            </ul>

            <div id='widget'>
              <a
                href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com/emergency-levels&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%20Emergency%20Levels&amp;tw_p=tweetbutton&amp;url=https://wmfd.com/emergency-levels'}
                target="_blank" rel="noopener noreferrer"
              >
                <span
                  className="btn"
                  style={{
                    background:"#1DA1F2",
                    borderRadius:"5",
                    color:"#fff",
                    fontSize:"11px",
                    fontWeight:"bold",
                    padding:"2px 5px 1px 5px",
                  }}>
                    <i className="fa fa-twitter" /> Tweet
                </span>
              </a>
              &nbsp;&nbsp;
              <div
                className="fb-share-button"
                data-href={'https://wmfd.com/emergency-levels'}
                data-layout="button_count"
              />
            </div>
          </div>
          <div className="cp-sidebar-content">
            <p>&nbsp;</p>
            <div>
              <p><b>LEVEL 1:</b> Roadways are hazardous with blowing and drifting snow. Roads are also icy. Drive very cautiously.</p>

              <p><b>LEVEL 2:</b> Roadways are hazardous with blowing and drifting snow. Only those who feel it is necessary to drive should be out on the roads. Contact your employer to see if you should report to work.</p>

              <p><b>LEVEL 3:</b> All roadways are closed to non-emergency personnel. No one should be out during these conditions unless it is absolutely necessary to travel. All employees should contact their employer to see if they should report to work. Those traveling on the roads may subject themselves to arrest.</p>

              <p><b>Snow Emergency:</b> All residents are asked to remove their vehicles from the City streets in order for the snow removal and emergency vehicles to be able to pass through the streets.  Residents and visitors are also advised to not travel the streets until the streets are safe and passable.</p>

              <p>Get these alerts sent to your cell phone or email: WMFD will send you a text message and/or email containing snow levels with counties you choose. Go to wmfd.com texting signup by clicking texting at the top of any page or go to https://wmfd.com/text . Under Section 2(Severe Weather) Select the county you wish to be notified about.</p>
            </div>
            <p>&nbsp;</p>
          </div>
        </div>

        <MoreArticles
          articleType={ { 'name': 'News' } }
          push={this.props.history.push }
        />

      </div>

    )
  }
}
