import React, { Component } from 'react';
import APIService from "../../API";
import Helpers from "../Helpers";
import Moment from "react-moment";
import ReactJWPlayer from 'react-jw-player';
// import OwlCarousel from 'react-owl-carousel';


export default class HomeLocalNews extends Component {

  static largeArticlePreviewCount = 10;

  state = {
    newsArticles: [],
    isNewsArticlesLoading: true,
  };

  componentDidMount() {
    this.fetchNewsArticles();
  }

  fetchNewsArticles = () => {
    this.setState({isNewsArticlesLoading: true});
    fetch(APIService.serverUrl + '/api/public/article', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        typeName: "News",
      }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({newsArticles: json});
        // console.log("Fetch News Articles Succeded")
        // console.log(json);
      } else {
        console.log("Fetch News Articles failed")
      }
      this.setState({isNewsArticlesLoading: false})
    })
  };

  changeButtonColor = () => {
    switch (this.btnColor) {
      case 'orange': this.btnColor = 'purple'; break;
      case 'purple': this.btnColor = 'pink'; break;
      case 'pink': this.btnColor = 'gray'; break;
      case 'gray': this.btnColor = 'green'; break;
      case 'green': this.btnColor = 'orange'; break;
      default: this.btnColor = 'orange'; break;
    } return this.btnColor;
  }

  renderArticleMedia = (article) => {
    if (article.articleVideo) {
      switch (article.articleVideo.articleVideoType.name) {
        case "JWPlatform":
          return (
            <ReactJWPlayer
              licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
              playlist={'//content.jwplatform.com/v2/media/' + article.articleVideo.location}
              playerId={'HLN'+article.articleVideo.location}
              playerScript={'https://content.jwplatform.com/players/' + article.articleVideo.location + '-lTCrLokv.js'}
              aspectRatio='16:9'
              isAutoPlay={false}
              customProps={{
                width: '100%',
                displaytitle: false,
                advertising: {
                  "admessage": "This video will resume in xx seconds",
                  "adscheduleid": "s1OFE6R4",
                  "client": "googima",
                  "schedule": [
                    {
                      "offset": "pre",
                      "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                      "type": "linear"
                    }
                  ]
                }
              }}
            />
          );
        default:
          return (
            <div className="thumbNailVideoContainer">
              <iframe id="" src={article.articleVideo.location} frameBorder="0"
                      allowFullScreen/>
            </div>
            // <div dangerouslySetInnerHTML={{__html: article.articleVideo.location}} />
          );
      }
    } else {
      return (
        <img src={Helpers.getOptimizedImage(article.mainImage, 360)} alt={article.headline} />

      )
    }
  };


  render() {

    return (

      <div>

        <div className="cp-news-grid-style-1">
          <div className="section-title blue-border">
            <h2>Local News</h2>
            <small>Our top stories from around the area</small>
          </div>
          <div className="row">
            <ul className="grid">
              {this.state.newsArticles.map((article, index) => {
                let buttonColor = this.changeButtonColor();
                return (index >= HomeLocalNews.largeArticlePreviewCount) ? null : (
                  <li className="col-md-6 col-sm-6" key={article.id}>
                    <div className="cp-news-post-excerpt" style={{height: "400px"}}>
                      <div className="cp-thumb">

                        { this.renderArticleMedia(article) }

                      </div>
                      <div className="cp-post-content">
                        <div className="catname">
                          <a
                            className={"catname-btn btn-"+buttonColor+" waves-effect waves-button"}
                            href={Helpers.articleToURLPath(article)}
                          >{article.tag}
                          </a>
                        </div>
                        <h4>
                          <a
                            href={Helpers.articleToURLPath(article)}
                          >{article.headline}
                          </a>
                        </h4>
                        <ul className="cp-post-tools">
                          <li>
                            <i className="icon-1"/>
                            <Moment format='ddd MMM DD YYYY h:mm a' >{article.createdAt}</Moment>
                          </li>
                          <br/>
                          <li>
                            <i className="icon-2"/>
                            {Helpers.authorsCommaSeparated(article)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>



          <div className="cp-news-grid-style-2 m20">
            <div className="section-title orange-border">
              <ul className="small-grid">
                {this.state.newsArticles.map((article, index) => {
                  return (index < HomeLocalNews.largeArticlePreviewCount) ? null : (
                    <li key={article.id} className="col-md-6 col-sm-6">
                      <div className="small-post">
                        <div className="cp-thumb">

                          { this.renderArticleMedia(article) }

                        </div>
                        <div className="cp-post-content">
                          <h3>
                            <a
                              href={Helpers.articleToURLPath(article)}
                            >{article.headline}
                            </a>
                          </h3>
                          <ul className="cp-post-tools">
                            <li>
                              <i className="icon-1"/>
                              <Moment format='ddd MMM DD YYYY h:mm a' >{article.createdAt}</Moment>
                            </li>
                            <li>
                              <i className="icon-2"/>
                              {Helpers.authorsCommaSeparated(article)}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>

      </div>

    )
  }
}
