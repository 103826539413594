import React, {Component} from 'react';
import LatestVideo from './Components/LatestVideo.js';
import APIService from "../API";
// import AD from 'react-google-publisher-tag';

export default class Gas extends Component {

  state = {
    gasBuddyMap: null,
    hasGasBuddyMapLoaded: false,
  };

  componentDidMount() {
    this.fetchGasBuddyResource();

    let lowManScript = document.createElement('script');
    lowManScript.setAttribute('language', 'JavaScript');
    lowManScript.setAttribute('type', 'text/javascript');
    lowManScript.setAttribute('src', 'https://df.gasbuddy.com/feed.df?k=1JRoQGTO2f9inFobwXaGJTkqmi3NLyMpjPpZxgOQMHN1fakocJFkWknO%2fElNmJINGT3yimJsDis%3d&i=28299');
    document.getElementById('gasbuddy_28299').appendChild(lowManScript);

    let natAverage = document.createElement('script');
    natAverage.setAttribute('language', 'JavaScript');
    natAverage.setAttribute('type', 'text/javascript');
    natAverage.setAttribute('src', 'https://df.gasbuddy.com/feed.gdf?k=1JRoQGTO2f9inFobwXaGJepol0ybjWrR4%2b4EmVw5EZGg%2fzQ6nTnpJB7vUfC4tVboeaPD7HaivJY%3d&i=11751');
    document.getElementById('gasbuddy_11751').appendChild(natAverage);

    let ohioHistorical = document.createElement('script');
    ohioHistorical.setAttribute('language', 'JavaScript');
    ohioHistorical.setAttribute('type', 'text/javascript');
    ohioHistorical.setAttribute('src', 'https://df.gasbuddy.com/feed.gdf?k=1JRoQGTO2f9inFobwXaGJepol0ybjWrR4%2b4EmVw5EZGg%2fzQ6nTnpJNALrJNzVSPhyq6bbt9S66k%3d&i=11752');
    document.getElementById('gasbuddy_11752').appendChild(ohioHistorical);
  }

  fetchGasBuddyResource = () => {
    fetch(APIService.serverUrl + "/api/public/layoutResource", {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        reference: "gasBuddyMap",
      })
    })
    .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({gasBuddyMap: ''+json.value})
      } else {
        console.log("Fetch Gas Buddy Map failed")
      }
      this.setState({ hasGasBuddyMapLoaded: true });
    })
  };

  renderGoogleAd5() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-5") })
  }

  renderGoogleAd15() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-15") })
  }

  renderGoogleAd25() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-25") })
  }


  render() {
    return (
      <div>

        <div className="cp-news-grid-style-1">
          <div className="section-title blue-border">
            <h2>GAS PRICES</h2> <small>Local Gas Prices</small>
          </div>

          <div id="div-gpt-ad-1530459263900-5">
            { this.renderGoogleAd5() }
          </div>

          <p>&nbsp;</p>
          <table>
            <tbody>
              <tr>
                <td>
                  <iframe
                    title="gbmap"
                    name="gbmap"
                    id="gbmap"
                    scrolling="no"
                    src='/gasmap.html'
                    style={{"width": "768px", "height": "551px"}}
                    width="0"
                    height="0"
                    frameBorder="0"
                  ></iframe>
                </td>
              </tr>
              <tr>
                <td align="center" style={{'fontFamily':'Arial', 'fontWeight':'bold', 'fontSize':'11px'}}>
                  <b>
                    <a href="http://www.OhioGasPrices.com" target="_blank" rel="noopener noreferrer">Ohio Gas Prices</a>&nbsp;provided by&nbsp;
                    <a href="http://www.GasBuddy.com" target="_blank" rel="noopener noreferrer">GasBuddy.com</a>
                  </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <a href="http://www.OhioGasPrices.com/mediafeed.aspx" target="_blank" rel="noopener noreferrer" style={{'fontFamily':'Arial', 'fontWeight':'bold', 'fontSize':'11px'}}>Click here to add this map to your website.
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          <div className="row">
            <ul className="grid">
              <li className="col-md-6 col-sm-6">
                <div className="cp-news-post-excerpt">
                  <div className="cp-thumb">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td align="center" style={{"fontFamily":"Arial", "fontWeight":"bold", "fontSize":"16px"}}>
                            Lowest Gas Prices in Mansfield
                          </td>
                        </tr>
                        <tr>
                          <td align="center" id="gasbuddy_28299"></td>
                        </tr>
                        <tr>
                          <td align="center" style={{"fontFamily":"Arial", "fontWeight":"bold", "fontSize":"11px"}}>
                            <b><a id="a_sitenm" href="https://www.OhioGasPrices.com/Mansfield/index.aspx"target="_blank" rel="noopener noreferrer"><span id="site_lnk">Mansfield Gas Prices</span></a>&nbsp;provided by <a href="https://www.GasBuddy.com" target="_blank" rel="noopener noreferrer">GasBuddy.com</a></b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="cp-post-content">
                    <div className="catname">
                      <a className="catname-btn btn-purple waves-effect waves-button" href="# ">Gas Price Map</a>
                    </div>
                    <ul className="cp-post-tools"></ul>
                  </div>
                </div>
              </li>
              <li className="col-md-6 col-sm-6">
                <div className="cp-news-post-excerpt">
                  <div className="cp-thumb">
                    <table width="100%" style={{ "height":"340px" }} >
                      <tbody>
                        <tr>
                          <td align="center" id="gasbuddy_11751"></td>
                        </tr>
                        <tr>
                          <td align="center" style={{ "align":"center", "fontFamily":"Courier New", "fontWeight":"bold", "fontSize":"14px" }}>
                            Find low Gas Prices at <a href="https://www.GasBuddy.com" target="_blank" rel="noopener noreferrer">GasBuddy.com</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="cp-post-content">
                    <div className="catname"><a className="catname-btn btn-pink waves-effect waves-button" id="nt" href="#nt">National Trends</a></div>
                    <ul className="cp-post-tools"></ul>
                  </div>
                </div>
              </li>
              <li>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style={{"fontWeight":"bold", "fontSize":"16px" }}></td>
                    </tr>
                    <tr>
                      <td align="center" id="gasbuddy_11752"></td>
                    </tr>
                    <tr>
                      <td align="center" style={{ "fontWeight":"bold", "fontSize":"11px", "fontFamily":"arial" }} >
                        <b><a id="PCa_sitenm" href="https://www.OhioGasPrices.com/retail_price_chart.aspx" target="_blank" rel="noopener noreferrer">Ohio Historical Gas Price Charts</a> Provided by <a href="https://www.GasBuddy.com" target="_blank" rel="noopener noreferrer">GasBuddy.com</a></b>&nbsp;<br />&nbsp;<br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li className="col-md-6 col-sm-6">
                <div className="cp-news-post-excerpt">
                  <div className="cp-thumb">
                    <img
                      src="http://wmfd.intelliweather.net/imagery/wmfd/rad_oh_mansfield_640x480.jpg"
                      alt="WMFD Doppler Radar" />
                  </div>
                  <div className="cp-post-content">
                    <div className="catname">
                      <a className="catname-btn btn-purple waves-effect waves-button" id="lr" href="#lr">Local Radar </a>
                    </div>
                    <ul className="cp-post-tools"></ul>
                  </div>
                </div>
              </li>
              <li className="col-md-6 col-sm-6">
                <div className="cp-news-post-excerpt">
                  <div className="cp-thumb">
                    <img
                      src="http://wmfd.intelliweather.net/imagery/wmfd/warnmap_oh_mansfield_640x480.jpg"
                      alt="WMFD Doppler Radar" />
                  </div>
                  <div className="cp-post-content">
                    <div className="catname">
                      <a className="catname-btn btn-purple waves-effect waves-button" id="ww" href="#ww">Watches & Warnings </a>
                    </div>
                    <ul className="cp-post-tools"></ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div id='div-gpt-ad-1530459263900-25'>
          { this.renderGoogleAd25() }
        </div>

        {/*<LatestVideo />*/}

      </div>
    )
  }
}
