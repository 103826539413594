import React, {Component} from 'react';
import APIService from "../../API";
import Helpers from "../Helpers";
import ReactJWPlayer from 'react-jw-player';


export default class Home extends Component {

  state = {
    eventArticles: [],
    isEventArticlesLoading: true,
  };

  componentDidMount() {
    this.fetchEventArticles();
  }

  fetchEventArticles = () => {
    this.setState({isEventArticlesLoading: true});
    fetch(APIService.serverUrl + '/api/public/article', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        typeName: "Events",
      }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({eventArticles: json})
        // console.log("Fetch Event Articles Succeded")
        // console.log(json)
      } else {
        console.log("Fetch Event Articles failed")
      }
      this.setState({isEventArticlesLoading: false})
    })
  };

  renderArticleMedia = (article) => {
    if (article.articleVideo) {
      switch (article.articleVideo.articleVideoType.name) {
        case "JWPlatform":
          return (
            <ReactJWPlayer
              licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
              playlist={'//content.jwplatform.com/v2/media/' + article.articleVideo.location}
              playerId={'HE'+article.articleVideo.location}
              playerScript={'https://content.jwplatform.com/players/' + article.articleVideo.location + '-lTCrLokv.js'}
              aspectRatio='16:9'
              isAutoPlay={false}
              customProps={{
                width: '100%',
                displaytitle: false,
                advertising: {
                  "admessage": "This video will resume in xx seconds",
                  "adscheduleid": "s1OFE6R4",
                  "client": "googima",
                  "schedule": [
                    {
                      "offset": "pre",
                      "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                      "type": "linear"
                    }
                  ]
                }
              }}
            />
          );
        default:
          return (
            <div dangerouslySetInnerHTML={{__html: article.articleVideo.location}} />
          );
      }
    } else {
      return (
        <img src={Helpers.getOptimizedImage(article.mainImage, 360)} alt={article.headline} />

      )
    }
  };


  render() {

    return (

      <div>

        <div className="cp-news-grid-style-3 m20">
          <div className="section-title purple-border">
            <h2>Events</h2>
            <small>Great things going on in the area. Send us yours at newsroom @ wmfd.com</small>
          </div>
          <div className="grid-holder">
            <div className="row">
               <ul className="cp-load-newsgrid">
                 {this.state.eventArticles.map((article) => {
                   return (
                     <li className="col-md-4 col-sm-4 cp-news-post" key={article.id} style={{ height: '196px' }}>
                       <div className="cp-thumb">

                         { this.renderArticleMedia(article) }

                       </div>
                       <h3>
                         <a href={Helpers.articleToURLPath(article)}>
                           {article.headline}
                         </a>
                       </h3>
                     </li>
                   )
                 })}
               </ul>
            </div>
           </div>
        </div>
      </div>

     )
   }
 }
