import React, {Component} from 'react';
import APIService from "../../API";
import Helpers from "../Helpers";
import Moment from "react-moment";
import 'moment-timezone';
import {withRouter} from "react-router";
import LiveTVSideBarComponent from "./LiveTVSideBarComponent";
// import AD from 'react-google-publisher-tag';


class Sidebar extends Component {

  state = {
    popularArticles: [],
    isPopularArticlesLoading: true,

    latestArticles: [],
    isLatestArticlesLoading: true,

    eventArticles: [],
    isEventArticlesLoading: true,
  };

  componentDidMount() {
    this.fetchPopularArticles();
    this.fetchLatestArticles();
    this.fetchEventArticles();
  }

  fetchPopularArticles = () => {
    this.setState({isPopularArticlesLoading: true});
    fetch(APIService.serverUrl + '/api/public/article', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderBy: "viewCount",
        limit: 5,
      }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({popularArticles: json})
        // console.log("Sidebar Fetch Popular Articles Succedded");
        // console.log(json);
      } else {
        console.log("Fetch Popular Articles failed")
      }
      this.setState({isPopularArticlesLoading: false})
    })
  };

  fetchLatestArticles = () => {
    this.setState({isLatestArticlesLoading: true});
    fetch(APIService.serverUrl + '/api/public/article', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        limit: 5,
      }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({latestArticles: json})
        // console.log("Sidebar Fetch Latest Articles Succedded");
        // console.log(json);
      } else {
        console.log("Fetch Latest Articles failed")
      }
      this.setState({isLatestArticlesLoading: false})
    })
  };

  fetchEventArticles = () => {
    this.setState({isEventArticlesLoading: true});
    fetch(APIService.serverUrl + '/api/public/article', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        typeName: "Events",
        limit: 5,
      }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({eventArticles: json})
        // console.log("Sidebar Fetch Event Articles Succedded");
        // console.log(json);
      } else {
        console.log("Fetch Event Articles failed")
      }
      this.setState({isEventArticlesLoading: false})
    })
  };

  renderGoogleAd0() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-0") })
  }
  renderGoogleAd1() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-1") })
  }
  renderGoogleAd8() {
    window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1530459263900-8') });
  }
  renderGoogleAd18() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-18") })
  }
  renderGoogleAd28() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-28") })
  }

  facebookMarkup() {
    return {
      __html:
      `<div className="widget facebook-widget">
        <h3 className="side-title"><a href="https://www.facebook.com/wmfdtv" target="_blank">Facebook</a></h3>
        <div className="cp-sidebar-content">
          <script>
            (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s);
              js.id = id;
              js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.3&appId=133982306765662";
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
          </script>
          <div className="fb-page fb_iframe_widget" data-href="https://www.facebook.com/wmfdtv" data-height="300px" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" data-show-posts="true" fb-xfbml-state="rendered" fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=133982306765662&amp;container_width=360&amp;height=300&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fwmfdtv&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;show_posts=true&amp;small_header=false">
            <span style="vertical-align: bottom; width: 340px; height: 300px;">
              <iframe name="f26abe5157d3af4" width="1000px" height="300px" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" title="fb:page Facebook Social Plugin" src="https://www.facebook.com/v2.3/plugins/page.php?adapt_container_width=true&amp;app_id=133982306765662&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2F__Bz3h5RzMx.js%3Fversion%3D42%23cb%3Df34e13789742dba%26domain%3Dwww.wmfd.com%26origin%3Dhttps%253A%252F%252Fwww.wmfd.com%252Ff498df85c1f0ce%26relation%3Dparent.parent&amp;container_width=360&amp;height=300&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fwmfdtv&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;show_posts=true&amp;small_header=false" className="" style="border: none; visibility: visible; width: 340px; height: 300px;">
              </iframe>
            </span>
          </div>
        </div>
      </div>`
    };
  }

  render() {
    return (

      <div className="sidebar side-bar right-sidebar">
        <div className="widget popular-posts">
          <h3 className="side-title">WMFD TV Live</h3>
          {this.props.location.pathname === "/live" ? null :
            <div className="ad-holder">
              <a href="https://wmfdsports.com/player/27547/27547">
                <img src="/images/WMFD-TV-Channel-Streaming-Thumbnail.jpg" width="320" height="180"/>
              </a>
            </div>
          }
        </div>

        <div className="widget advertisement">
          <div className="ad-holder">
            <div
              id="div-gpt-ad-1530459263900-8"
              data-google-query-id="COmCyZals94CFUepaQodcZEC-Q"
              className={ this.props.location.pathname } >
              { this.renderGoogleAd8() }
            </div>
          </div>
        </div>

        <div className="widget popular-post">
          <h3 className="side-title">Popular Posts</h3>
          <div className="cp-sidebar-content">
            <ul className="small-grid">
              {this.state.popularArticles.map(article => {
                return (
                  <li key={article.id}>
                    <div className="small-post">
                      <div className="cp-thumb">
                        <img
                          alt={article.headline}
                          src={Helpers.getOptimizedImage(article.mainImage, 263)}
                        />
                      </div>
                      <div className="cp-post-content">
                        <h3><a href={Helpers.articleToURLPath(article)}>{article.headline}</a></h3>
                        <ul className="cp-post-tools">
                          <li>
                            <i className="icon-1"></i>
                            <Moment format='ddd MMM DD YYYY h:mm a' >{article.createdAt}</Moment>
                          </li>
                          <li><i className="icon-2"></i> {Helpers.authorsCommaSeparated(article)}</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="widget advertisement">
          <div className="ad-holder">
            <div id='div-gpt-ad-1530459263900-18' className={ this.props.location.pathname } >
              { this.renderGoogleAd18() }
            </div>
          </div>
        </div>

        <div className="widget latest-posts">
          <h3 className="side-title">Latest Posts</h3>
          <div className="cp-sidebar-content">
            <ul className="small-grid">
              {this.state.latestArticles.map(article => {
                return (
                  <li key={article.id}>
                    <div className="small-post">
                      <div className="cp-thumb">
                        <img
                          alt={article.headline}
                          src={Helpers.getOptimizedImage(article.mainImage, 263)}
                        />
                      </div>
                      <div className="cp-post-content">
                        <h3><a href={Helpers.articleToURLPath(article)}>{article.headline}</a></h3>
                        <ul className="cp-post-tools">
                          <li>
                            <i className="icon-1"></i>
                            <Moment format='ddd MMM DD YYYY h:mm a' >{article.createdAt}</Moment>
                          </li>
                          <li>
                            <i className="icon-3"></i>
                            {article.articleTypes.map((v,i) => (i ? ", ":"") + v.name )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div  className="widget latest-posts">
          <h3  className="side-title">Events / Entertainment</h3>
          <div  className="cp-sidebar-content">
            <ul  className="small-grid">
              {this.state.eventArticles.map(article => {
                return (
                  <li key={article.id}>
                    <div className="small-post">
                      <div className="cp-thumb">
                        <img
                          alt={article.headline}
                          src={Helpers.getOptimizedImage(article.mainImage, 263)}
                        />
                      </div>
                      <div className="cp-post-content">
                        <h3><a href={Helpers.articleToURLPath(article)}>{article.headline}</a></h3>
                        <ul className="cp-post-tools">
                          <li>
                            <i className="icon-1"></i>
                            <Moment format='ddd MMM DD YYYY h:mm a' >{article.createdAt}</Moment>
                          </li>
                          <li>
                            <i className="icon-3"></i>
                            {article.articleTypes.map((v,i) => (i ? ", ":"") + v.name )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="widget sidebar-featured-post">
          <h3 className="side-title">LOCAL RADAR</h3>
          <div className="cp-sidebar-content">
            <div className="side-featured-slider owl-carousel owl-theme">
              <div className="item" style={{ width:"360px" }}>
                <a
                  href='/weather'
                  style={{cursor:'pointer'}}
                  onClick={ () => { this.props.history.push('/weather'); } }
                >
                  <img
                    src="https://wmfd.intelliweather.net/imagery/wmfd/rad_oh_mansfield_640x480.jpg"
                    alt="Local Radar"
                    style={{ "width":"360px", "height":"203px" }} />
                </a> {/* /graphics/360x203/localradar.jpg */}
              </div>
            </div>
          </div>
        </div>
        <div className="widget sidebar-featured-post">
          <h3 className="side-title">7 Day Forecast</h3>
          <div className="cp-sidebar-content">
            <div className="side-featured-slider owl-carousel owl-theme">
              <div className="item" style={{ width:"360px" }}>
                <a
                  href='/weather'
                  style={{cursor:'pointer'}}
                  onClick={ () => { this.props.history.push('/weather'); } }
                >
                  <img
                    src="https://wmfd.intelliweather.net/imagery/wmfd/7dayfc-iwin_oh_mansfield_640x480.jpg"
                    alt="7 Day Forecast" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="widget sidebar-featured-post">
          <h3 className="side-title">SatRad</h3>
          <div className="cp-sidebar-content">
            <div className="side-featured-slider owl-carousel owl-theme">
              <div className="item" style={{ width:"360px" }}>
                <a
                  href='/weather'
                  style={{cursor:'pointer'}}
                  onClick={ () => { this.props.history.push('/weather'); } }
                >
                  <img
                    src="https://wmfd.intelliweather.net/imagery/wmfd/satrad_reg_640x480_12.jpg"
                    alt="WMFD SatRad"
                    style={{ "width":"360px", "height":"203px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="widget advertisement">
          <div className="ad-holder">
            <div id='div-gpt-ad-1530459263900-28' className={ this.props.location.pathname } >
              { this.renderGoogleAd28() }
            </div>
          </div>
        </div>

        <div className="widget advertisement">
          <div className="">
            <div dangerouslySetInnerHTML={this.facebookMarkup()} />
          </div>
        </div>

        <div className="widget advertisement">
          <div className="ad-holder">
            <div id='div-gpt-ad-1530459263900-1' className={ this.props.location.pathname } >
              { this.renderGoogleAd1() }
            </div>
            <p>&nbsp;</p>
            <div id='div-gpt-ad-1530459263900-0' className={ this.props.location.pathname } >
              { this.renderGoogleAd0() }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Sidebar)

/*



*/
