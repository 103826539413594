import React, {Component} from 'react';
import HomeEvents from './Components/HomeEvents'
import { Route } from "react-router-dom";
import APIService from '../API.js';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';

export default class Contest extends Component {

  state = {
    id: 0,
    contestName: '',
    externalUrl: '',
    content: '',
    // content: '<iframe src="https://builder.zoomradar.net/weather_builder/weather.page.v4.php?fw=280&fh=840&z=44901&ft=Your%20Extended%20Forecast&ar=50&l=english&lo=wmfd.png&ae=1" width="100%" height="840" title="iframe"></iframe>',
    startDate: new moment(),
    endDate: new moment(),
    image: {
      id: 0,
      filename: '',
      location: '', //http://api.wmfd.solsys.local:8083
      height: 0,
      width: 0,
    },
    displayType: {
      id: 0,
      name: '',
    },

    isArticleLoading: true,
  };

  contestId = this.props.match.params.id;
  currentPath = this.props.location.pathname;

  componentDidMount() {
    this.getContest();
  }

  getContest = () => {
    /* API CALL to get article information: Then populate local state */
    this.setState({ isArticleLoading: true, });
    fetch(APIService.serverUrl + '/api/public/contest/' + this.contestId, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("getContest Failed: ", response.status, response.statusText);
        return null
      }
    }) // Checks response and converts to JSON
    .then((json) => {
      if (json) {
        // console.log("getContest Succeded: json - ",json);
        this.setState({
          id: json.id,
          contestName: json.name,
          externalUrl: json.externalUrl,
          content: json.content,
          startDate: json.startDate,
          endDate: json.endDate,
          image: json.image,
          displayType: json.displayType,
          isArticleLoading: false,
        })
      }
    })
  };

  render() {
    return (

      <div>
        <div className="cp-news-post-excerpt">
          <div className="">
            <img
              src={APIService.serverUrl+this.state.image.location}
              alt={this.state.image.filename}
              height={this.state.image.height} width={this.state.image.width} />
          </div>
          <div className=" cp-post-content">
            <h1
              onClick={() => this.props.history.push(this.currentPath)}
              style={{ color:'#2e6da4', cursor:'pointer' }}
            >
              {this.state.contestName}
            </h1>

            <ul className="cp-post-tools">
              <li>
                <i className="icon-1"/> Contest End Date:&nbsp;
                <Moment format='ddd MMM DD YYYY h:mm a' >{this.state.endDate}</Moment>
              </li>
            </ul>

            <div id='widget'>
              <a
                href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com' + this.currentPath + '&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%20' + this.state.name + '&amp;tw_p=tweetbutton&amp;url=https://wmfd.com' + this.currentPath}
                target="_blank" rel="noopener noreferrer"
              >
                <span
                  className="btn"
                  style={{
                    background:"#1DA1F2",
                    borderRadius:"5",
                    color:"#fff",
                    fontSize:"11px",
                    fontWeight:"bold",
                    padding:"2px 5px 1px 5px",
                  }}>
                    <i className="fa fa-twitter" /> Tweet
                </span>
              </a>
              &nbsp;&nbsp;
              <div
                className="fb-share-button"
                data-href={'https://wmfd.com' + this.currentPath}
                data-layout="button_count"
              />
            </div>
          </div>
          <div className="cp-sidebar-content">
            <p>&nbsp;</p>
            <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
            <p>&nbsp;</p>
          </div>
        </div>

        <Route path="/contest" component={HomeEvents} />

      </div>

    )
  }
}
