import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Divider, Form, Header, Icon, Modal, Segment} from "semantic-ui-react";
import APIService from "../API";


class SmallBusinessForm extends Component {

  state = {
    name: "",
    address: "",
    phoneNumber: "",
    website: "",
    description: "",
    isCarryOutOption: false,
    isDeliveryOption: false,
    isCurbsideOption: false,
    contactName: "",
    contactPhone: "",
    hasOnlineOrdering: false,
    onlineOrderingNote: "",
    deliveryNote: "",
    hours: "",

    isSubmittingRestaurant: false,
    isSubmissionModalOpen: false,
  }

  submitBusiness = (event) => {
    event.preventDefault();
    this.setState({isSubmittingRestaurant: true});
    fetch(APIService.serverUrl + "/api/public/promotional-restaurant", {
      method: "POST",
      headers: {"content-type": "application/json"},
      body: JSON.stringify({
        name: this.state.name,
        address: this.state.address,
        phoneNumber: this.state.phoneNumber,
        website: this.state.website,
        description: this.state.description,
        isCarryOutOption: this.state.isCarryOutOption,
        isDeliveryOption: this.state.isDeliveryOption,
        isCurbsideOption: this.state.isCurbsideOption,
        contactName: this.state.contactName,
        contactPhone: this.state.contactPhone,
        hasOnlineOrdering: this.state.hasOnlineOrdering,
        onlineOrderingNote: this.state.onlineOrderingNote,
        deliveryNote: this.state.deliveryNote,
        hours: this.state.hours,
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.setState({isSubmissionModalOpen: true})
        }
        this.setState({isSubmittingRestaurant: false});
      })
      .then((json) => {
        if (json) {

        }
      })
  }

  validateForm = () => {
    let isFormValid = true;
    [
      "name",
      "address",
      "phoneNumber",
      "description",
      "contactName",
      "contactPhone",
      "hours"
    ].forEach(key => {
      const value = this.state[key];
      if (value === undefined || value === null || value === "") isFormValid = false;
    })

    return isFormValid
  }

  render = () => {
    let isFormValid = this.validateForm();

    return(
      <Segment>
        <h2 style={{textDecoration: "underline"}}>Add your Restaurant</h2>
        <Form onSubmit={this.submitBusiness}>

          <Form.Group>
            <Form.Input
              required
              width={10}
              label="Restaurant Name"
              placeholder="XYZ Restaurant"
              value={this.state.name}
              onChange={(event) => {
                this.setState({name: event.target.value})
              }}
            />
            <Form.Input
              required
              width={6}
              label="Restaurant Number"
              type="tel"
              placeholder="4191230987"
              value={this.state.phoneNumber}
              onChange={(event) => {
                this.setState({phoneNumber: event.target.value})
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              required
              style={{minWidth: "300px"}}
              label="Address"
              placeholder="123 Main Street Mansfield, OH"
              value={this.state.address}
              onChange={(event) => {
                this.setState({address: event.target.value})
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              required
              style={{minWidth: "250px"}}
              label="Brief Hours Description"
              placeholder="M-F 9-5, Sat 11-4"
              value={this.state.hours}
              onChange={(event) => {
                this.setState({hours: event.target.value})
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              style={{minWidth: "250px"}}
              label="Website"
              placeholder="www.example.com"
              value={this.state.website}
              onChange={(event) => {
                this.setState({website: event.target.value})
              }}
            />
          </Form.Group>

          <h4 style={{fontSize: '13px', alignSelf: 'center'}}>Select available services:</h4>
          <Form.Group>
            <Form.Checkbox
              label="Carry-Out"
              name='carryout'
              toggle
              checked={this.state.isCarryOutOption}
              onChange={() => {
                this.setState({ isCarryOutOption: !this.state.isCarryOutOption })
              }}
            />
            <Form.Checkbox
              label="Delivery"
              name='delivery'
              toggle
              checked={this.state.isDeliveryOption}
              onChange={() => {
                this.setState({ isDeliveryOption: !this.state.isDeliveryOption })
              }}
            />
            <Form.Checkbox
              label="Curb-Side/Drive-Thru"
              name='curbside'
              toggle
              checked={this.state.isCurbsideOption}
              onChange={() => {
                this.setState({ isCurbsideOption: !this.state.isCurbsideOption })
              }}
            />
            <Form.Checkbox
              label="Online Ordering"
              name='onlineOrdering'
              toggle
              checked={this.state.hasOnlineOrdering}
              onChange={() => {
                this.setState({ hasOnlineOrdering: !this.state.hasOnlineOrdering })
              }}
            />
          </Form.Group>

          {!this.state.isDeliveryOption ? null :
            <Form.Group>
              <Form.Input
                style={{minWidth: "250px"}}
                label="Optional Delivery Note"
                placeholder="grubhub.com only"
                value={this.state.deliveryNote}
                onChange={(event) => {
                  this.setState({deliveryNote: event.target.value})
                }}
              />
            </Form.Group>
          }

          {!this.state.hasOnlineOrdering ? null :
            <Form.Group>
              <Form.Input
                style={{minWidth: "250px"}}
                label="Optional Online Ordering Note"
                placeholder="doordash.com only"
                value={this.state.onlineOrderingNote}
                onChange={(event) => {
                  this.setState({onlineOrderingNote: event.target.value})
                }}
              />
            </Form.Group>
          }

          <Form.TextArea
            required
            label="Description"
            placeholder="Brief description"
            value={this.state.description}
            onChange={(event) => {
              this.setState({description: event.target.value})
            }}
          />

          <Divider hidden />

          <br/>
          <em>The following is for WMFD staff to contact you, and will not be displayed publicly</em>
          <br/><br/>

          <Form.Group>
            <Form.Input
              required
              label="Contact Name"
              placeholder="John Smith"
              value={this.state.contactName}
              onChange={(event) => {
                this.setState({contactName: event.target.value})
              }}
            />
            <Form.Input
              required
              label="Contact Phone Number"
              placeholder="4191230987"
              type="tel"
              value={this.state.contactPhone}
              onChange={(event) => {
                this.setState({contactPhone: event.target.value})
              }}
            />
          </Form.Group>

          <Divider hidden />

          <Button
            type="submit"
            content={isFormValid ? "Submit" : "Please fill in required fields"}
            color={isFormValid ? "green" : "gray"}
            disabled={!isFormValid}
            loading={this.state.isSubmittingRestaurant}
          />
        </Form>

        <Modal open={this.state.isSubmissionModalOpen} size='small'>
          <Modal.Content style={{textAlign: "center"}}>
            <Header as='h2' icon>
              <Icon name='check circle' color='green' />
              Thank you for your submission
              <Header.Subheader>
                We wish you well during these times!
              </Header.Subheader>
            </Header>
            <div>
              <Button
                content="Finish"
                onClick={() => {
                  this.setState({isSubmissionModalOpen: false});
                  this.props.history.push("/restaurants")
                }}
              />
            </div>
          </Modal.Content>
        </Modal>
      </Segment>
    )
  }
}

export default withRouter(SmallBusinessForm);
