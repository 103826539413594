import React, {Component} from 'react';
// import MoreArticles from './Components/MoreArticles.js';
import APIService from "../API";


export default class PlayOfTheWeek extends Component {

  state = {
    playOfTheWeekState: null,
  };

  componentDidMount() {
    this.fetchLayoutResource('playOfTheWeek');
  }

  fetchLayoutResource = (type) => {
    fetch(APIService.serverUrl + "/api/public/layoutResource", {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        reference: type,
      })
    })
    .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({ playOfTheWeekState: json.value })
      } else {
        console.log("Fetch "+type+" Resource failed")
      }
    })
  };


  render() {
    return (
      <div>

        <div dangerouslySetInnerHTML={{__html: this.state.playOfTheWeekState}} />

      </div>
    )
  }
}

/*

*/
