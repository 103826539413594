import React, {Component} from 'react';
import ReactJWPlayer from 'react-jw-player';


export default class HomeWatchWMFD extends Component {

  renderGoogleAd32() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-32") })
  }

  render() {
    return (

      <div className="cp-news-grid-style-5 m20">
        <div className="section-title orange-border">
          <h2>WATCH WMFD</h2>
          <small>Watch WMFD TV Anytime Anywhere.</small>
        </div>
        <div>
          <div className="cp-news-list">
            <ul className="row">
              <li className="col-md-6 col-sm-6">
                <div className="cp-thumb">

                  {/* this.state.videoID */}
                  <ReactJWPlayer
                    licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
                    playlist='//content.jwplatform.com/v2/media/BNKFfDNN'
                    playerId='BNKFfDNN'
                    playerScript='https://content.jwplatform.com/players/BNKFfDNN-BNKFfDNN.js'
                    aspectRatio='16:9'
                    customProps={{
                      width:'100%',
                      displaytitle: false,
                      advertising: {
                        "admessage": "This video will resume in xx seconds",
                        "adscheduleid": "s1OFE6R4",
                        "client": "googima",
                        "schedule": [
                          {
                            "offset": "pre",
                            "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                            "type": "linear"
                          }
                        ]
                      }
                    }}
                  />

                </div>
              </li>
              <li className="col-md-6 col-sm-6">
                <div className="cp-post-content">
                  <h3><a href="https://preview-16bkbirs.jwpapp.com/">High School Football in HD </a></h3>
                  <ul className="cp-post-tools">
                    <li><i className="icon-2"></i> WMFD Sports</li>
                  </ul>
                  <p>WMFD Sports will broadcast over 20 complete regular season high school football games. Watch all these games right now in our <a href="https://preview-16bkbirs.jwpapp.com/" target="new">WMFD Sports Gameroom. </a></p>
                </div>
              </li>
            </ul>
          </div>

          <div className="cp-news-list">
            <ul className="row">
              <li className="col-md-6 col-sm-6">
                <div className="cp-thumb">

                  <ReactJWPlayer
                    licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
                    playlist='//content.jwplatform.com/v2/media/XxvhU0Hc'
                    playerId='XxvhU0Hc'
                    playerScript='https://content.jwplatform.com/players/XxvhU0Hc-XxvhU0Hc.js'
                    aspectRatio='16:9'
                    customProps={{
                      width:'100%',
                      displaytitle: false,
                      advertising: {
                        "admessage": "This video will resume in xx seconds",
                        "adscheduleid": "s1OFE6R4",
                        "client": "googima",
                        "schedule": [
                          {
                            "offset": "pre",
                            "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                            "type": "linear"
                          }
                        ]
                      }
                    }}
                  />

                </div>
              </li>
              <li className="col-md-6 col-sm-6">
                <div className="cp-post-content">
                  <h3><a href="https://preview-16bkbirs.jwpapp.com/">High School Soccer in HD</a></h3>
                  <ul className="cp-post-tools">
                    <li><i className="icon-2"></i> WMFD Sports</li>
                  </ul>
                  <p>WMFD Sports now carries LIVE high school soccer! Watch them now in our <a href="https://preview-16bkbirs.jwpapp.com/" target="new">WMFD Sports Gameroom. </a></p>
                </div>
              </li>
            </ul>
          </div>

          <div className="cp-news-list">
            <ul className="row">
              <li className="col-md-6 col-sm-6">
                <div className="cp-thumb">

                  <ReactJWPlayer
                    licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
                    playlist='//content.jwplatform.com/v2/media/1bl9LOTj'
                    playerId='1bl9LOTj'
                    playerScript='https://content.jwplatform.com/players/1bl9LOTj-1bl9LOTj.js'
                    aspectRatio='16:9'
                    customProps={{
                      width:'100%',
                      displaytitle: false,
                      advertising: {
                        "admessage": "This video will resume in xx seconds",
                        "adscheduleid": "s1OFE6R4",
                        "client": "googima",
                        "schedule": [
                          {
                            "offset": "pre",
                            "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                            "type": "linear"
                          }
                        ]
                      }
                    }}
                  />

                </div>
              </li>
              <li className="col-md-6 col-sm-6">
                <div className="cp-post-content">
                  <h3><a href="/#">Catch all of WMFD's local programming in our Videoroom.</a></h3>
                  <ul className="cp-post-tools">
                    <li><i className="icon-2"></i> Master Control</li>
                  </ul>
                  <p>Programs like <b><a href="/i-Love-My-Job">I Love My Job</a>, <a href="/focus-on-north-central-ohio">Focus</a>, <a href="/senior-living">Senior Living</a>,<a href="/st-john"> St. Johns </a></b> and many more. Clck Video at the top of the page to watch. </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="widget advertisement">
            <div className="ad-holder">
              <div id="div-gpt-ad-1530459263900-32" data-google-query-id="CPr20rCln94CFQvZwAodtJwDKw">

                <div dangerouslySetInnerHTML={{ __html: `<script>googletag.cmd.push(function() {googletag.display('div-gpt-ad-1530459263900-32');});</script>` }} />

              </div>
            </div>
          </div>
        </div>
      </div>


    )
  }
}

/*

*/
