import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {Dropdown, Segment, Table, TableFooter, TableHeaderCell, Button, Image} from "semantic-ui-react";
import moment from "moment";
import APIService from "../API";
import Helpers from "./Helpers";



class Scores extends Component {

    state = {
      seasons: [],
      selectedSeasonId: null,
      selectedSeasonStart: null,
      selectedSeasonEnd: null,

      selectedWeekStart: null,

      games: [],

      isFetchingSeasons: false,
      isFetchingSeason: false,
      isFetchingGames: false,
    };

    componentDidMount() {
      this.getSeasons();
    }

    getSeasons = () => {
      this.setState({isFetchingSeasons: true});
      fetch(APIService.serverUrl + "/api/public/seasons")
        .then( (response) => {
          if (response.ok) {
            return response.json()
          } else {
            return null;
          }
        })
        .then( (json) => {
          if (json) {
            this.setState( {seasons: json})
            if (json.length) {
              this.setState({selectedSeasonId: json[0].id})
              this.getSelectedSeason(json[0].id);
            }
          }
          this.setState( {isFetchingSeasons: false})
        })
    };

    getSelectedSeason = (selectedSeasonId) => {
      this.setState({
        isFetchingSeason: true,
        selectedSeasonStart: null,
        selectedSeasonEnd: null,
        selectedWeekStart: null,
        games: []
      });
      fetch(APIService.serverUrl + "/api/public/season/" + selectedSeasonId)
        .then( (response) => {
          if (response.ok) {
            return response.json()
          } else {
            return null;
          }
        })
        .then( (json) => {
          if (json) {
            let now = moment();
            let seasonStart = moment(json.startAt);
            let seasonEnd = moment(json.endAt);
            let week = moment().startOf('isoWeek');
            if (now.isBefore(seasonStart)) {
              week = moment(seasonStart).startOf('isoWeek');
            } else if (now.isAfter(seasonEnd)) {
              week = moment(seasonEnd).startOf('isoWeek')
            }
            this.setState( {
              selectedSeasonStart: seasonStart,
              selectedSeasonEnd: seasonEnd,
              selectedWeekStart: week,
              games: json.games
            })
          }
          this.setState( {isFetchingSeason: false})

        })
    };

    createWeeks = () => {
      let start = this.state.selectedSeasonStart;
      let end = this.state.selectedSeasonEnd;

      // Start and End is not set upon componentDidMount()
      let loop = start !== null && end !== null;
      let weeks = [];
      while (loop) {
        let week = moment(start).startOf('isoWeek').add(weeks.length, 'weeks');
        if (week.isAfter(end)) {
          loop = false;
        } else {
          weeks.push({
            text: "Week " + (weeks.length + 1) + " " + week.format('M/D') + ' - ' + moment(week).endOf('isoWeek').format('M/D'),
            key: week.format('YYYY-MM-DD'),
            value: week.format('YYYY-MM-DD'),
          })
        }
      }
      return weeks
    };

    displaySecondarySchool = (game) => {
      let secondarySchool = "N/A";
      if (game.hasOtherSecondarySchool) {
        secondarySchool = game.otherSecondarySchoolName;
      } else if (game.secondarySchool) {
        secondarySchool = game.secondarySchool.name;
      }
      return secondarySchool;
    };

    displayPrimarySchool = (game) => {
      let primarySchool = "N/A";
      if (game.hasOtherPrimarySchool) {
        primarySchool = game.otherPrimarySchoolName;
      } else if (game.primarySchool) {
        primarySchool = game.primarySchool.name;
      }
      return primarySchool;
    };

    render() {

      let weekStart = this.state.selectedWeekStart;
      let weekEnd = moment(weekStart).endOf('isoWeek');
      let games = this.state.games.filter((game) => {
        return moment(game.eventStartAt).isAfter(weekStart) && moment(game.eventStartAt).isBefore(weekEnd)
      });

        return(
            <div className='scoreboardContainer'>
                <div className='headerMenu' style={{marginBottom: '40px'}}>
                    <h1 style={{color: "rgb(46, 109, 164)"}}>High School Sports Scoreboard</h1>

                    <Button.Group style={{width: '100%'}}>
                      {this.state.seasons.map((season) => {
                        return(
                          <Button
                            active={season.id === this.state.selectedSeasonId ? true : false}
                            style={{
                              color: (season.id === this.state.selectedSeasonId) ? '#fff' : '#aaa',
                              border: (season.id === this.state.selectedSeasonId) ? 'none' : '1px solid #aaa',
                              backgroundColor: (season.id === this.state.selectedSeasonId) ? '#1278bf' : '#fff',
                            }}
                            content={season.name}
                            key={season.id.toString()}
                            name={season.name}
                            onClick={() => {
                              this.setState( {selectedSeasonId: season.id})
                              this.getSelectedSeason(season.id);

                            }}
                          />
                        )
                      })}
                    </Button.Group>

                    <Dropdown
                        fluid
                        selection
                        style={{marginTop: '20px', width: '210px'}}
                        placeholder='Select Viewable Week'
                        disabled={this.state.selectedSeasonId === null && this.state.isFetchingSeason}
                        value={this.state.selectedWeekStart ? this.state.selectedWeekStart.format('YYYY-MM-DD') : null}
                        options={this.createWeeks()}
                        onChange={(event, data) => {
                          this.setState({
                            selectedWeekStart: moment(data.value, 'YYYY-MM-DD')
                          });
                        }}
                    />
                </div>

                <div className='scoresContainer'>
                  {games.map((game) => {
                    return (
                      <Table
                        style={{marginTop: '20px'}}
                      >

                      {/*Game Date, "Scores"*/}
                        <Table.Header>
                            <Table.Row>
                                <TableHeaderCell
                                  colSpan='2'
                                  style={{backgroundColor: '#f5faff'}}
                                >
                                    {moment(game.eventStartAt).format('ddd, MMM Do')}
                                </TableHeaderCell>
                                <TableHeaderCell
                                  colSpan='1'
                                  style={{backgroundColor: '#f5faff', fontSize: '16px', width: '100px'}}
                                  textAlign='center'
                                >
                                    Scores
                                </TableHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        {/*Schools*/}
                        <Table.Body>

                            {/*Secondary (Away) School*/}
                            <Table.Row>

                              <Table.Cell style={{width: '65px', height: '65px', padding: '5px'}}>
                                {game.secondarySchool && game.secondarySchool.logoImage ?
                                  <Image
                                    width='55'
                                    height='55'
                                    src={Helpers.getOptimizedImage(game.secondarySchool.logoImage, 150)}
                                  />
                                  :
                                  null
                                }
                              </Table.Cell>
                              <Table.Cell
                                verticalAlign='middle'
                                style={{fontSize: '18px'}}
                              >
                                {this.displaySecondarySchool(game)}
                              </Table.Cell>
                              <Table.Cell
                                textAlign='center'
                                verticalAlign='middle'
                                style={{fontSize: '18px'}}
                              >
                                {game.secondarySchoolScore !== undefined ? game.secondarySchoolScore : "-"}
                              </Table.Cell>
                            </Table.Row>

                            {/*Primary (Home) School*/}
                            <Table.Row>
                              <Table.Cell style={{width: '65px', height: '65px', padding: '5px'}}>
                                {game.primarySchool && game.primarySchool.logoImage ?
                                  <Image
                                    width='55'
                                    height='55'
                                    src={Helpers.getOptimizedImage(game.primarySchool.logoImage, 150)}
                                  />
                                  :
                                  null
                                }
                              </Table.Cell>
                              <Table.Cell
                                verticalAlign='middle'
                                style={{fontSize: '18px'}}
                              >
                                {this.displayPrimarySchool(game)}
                              </Table.Cell>
                              <Table.Cell
                                textAlign='center'
                                verticalAlign='middle'
                                style={{fontSize: '18px'}}
                              >
                                {game.primarySchoolScore !== undefined ? game.primarySchoolScore : "-"}
                              </Table.Cell>
                            </Table.Row>
                        </Table.Body>

                        <Table.Footer>
                            <Table.Cell
                                colSpan='3'
                                textAlign='center'
                                style={{
                                    padding: '2px',
                                    borderTop: '1px solid rgba(34, 36, 38, 0.14902)',
                                    backgroundColor: '#5290bf',
                                    color: 'white',
                                }}
                            >
                                {game.gameStatus.name}
                            </Table.Cell>
                        </Table.Footer>
                      </Table>
                    )
                  })}
                </div>
            </div>
        )
    };
};

export default withRouter(Scores);
