import React, { Component } from 'react'
import {Header, Label, List, Segment} from "semantic-ui-react";
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";

class ListRaces extends Component {
  render = () => {

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
      cx, cy, midAngle, innerRadius, outerRadius, percent, index, name,
    }) => {
       const radius = innerRadius + (outerRadius - innerRadius) * 1.35;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {name}
        </text>
      );
    };

    return (
      <div>
        {this.props.races.map(race => {

          let data = race.candidates.map(candidate => {
            let votes = 0
            if (race.totalVoteCount) {
              votes = candidate.voteCount
            } else {
              votes = candidate.voteCounts.reduce((result, value) => result + value.forCount, 0)
            }
            return {
              name: candidate.name,
              value: votes,

            }
          });

          let colors = [
            "#F78181", "#F79F81", "#F7BE81", "#F5DA81", "#81F7F3", "#81DAF5", "#81BEF7", "#819FF7", "#8181F7", "#9F81F7", "#BE81F7", "#DA81F5", "#F781F3", "#FA5858", "#FA8258", "#FAAC58", "#F7D358", "#58FAD0", "#58FAF4", "#58D3F7", "#58ACFA", "#5882FA", "#5858FA", "#8258FA", "#AC58FA", "#D358F7", "#FA58F4", "#FE2E2E", "#FE642E", "#FE9A2E", "#FACC2E", "#2EFEC8", "#2EFEF7", "#2ECCFA", "#2E9AFE", "#2E64FE", "#2E2EFE", "#642EFE", "#9A2EFE"
          ]

          let areNoVotes = data.filter(v => v.value !== 0).length === 0

          if (areNoVotes) {
            data = [{
              name: "Results Coming Soon",
              value: 10
            }];
          }
          return (
            <Segment>
              <Header textAlign='center' size='large' content={race.name} />
              <ResponsiveContainer height={250}>
                <PieChart width={730} height={250}>
                  {console.log("data", data)}
                  <Pie
                    data={data}
                    labelLine
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    dataKey="value"
                    fill="#1C3D4D"
                  >
                    {
                      data.map((entry, index) => <Cell key={`cell-${index}`} fill={areNoVotes ? ["#ddd"] : colors[index % colors.length]} />)
                    }
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div style={{display: "flex"}}>
                <div style={{minWidth: "60px", marginRight: '10px'}}>
                  <Header content='Votes' sub/>
                </div>
                <div>
                  <Header content='Candidates' sub/>
                </div>
              </div>

              <List>
                {race.candidates.map((candidate, index) => {
                  let votes = 0;
                  let labelColor = colors[index];
                  if (race.totalVoteCount) {
                    votes = candidate.voteCount
                  } else {
                    votes = candidate.voteCounts.reduce((result, value) => result + value.forCount, 0)
                  }
                  console.log("labelColor", labelColor)

                  return (
                    <List.Item style={{display: "flex"}}>
                      <div style={{minWidth: "60px", marginRight: '10px'}}>
                        <Label
                          circular
                          style={{backgroundColor: labelColor, color: '#fff'}}
                        >
                          {votes.toLocaleString()}
                        </Label>
                      </div>
                      <div>
                        <Header
                          content={candidate.name + " (" + candidate.party.name + ")"}
                        />
                        {race.totalVoteCount ? null :
                          candidate.voteCounts.map((voteCount, index) => (index ? ", ":"") + voteCount.electionCounty.name + " (" + voteCount.forCount + ")")
                        }
                      </div>
                    </List.Item>
                  )
                })}
              </List>
            </Segment>
          )
        })}
      </div>
    )
  }
}

export default ListRaces
