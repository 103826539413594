import React, {Component} from 'react';
import MoreArticles from "./MoreArticles";
import APIService from "../../API";

export default class LiveTVComponent extends Component {

  state = {
    liveTVPageValue: undefined,
    isLiveTVPageLoading: false,
  }

  componentDidMount() {
    this.fetchWMFDLivePage()
  }

  fetchWMFDLivePage = () => {
    this.setState({isLiveTVPageLoading: true})
    fetch(APIService.serverUrl + "/api/public/layoutResource", {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        reference: "wmfdLivePage"
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("Fetch Live TV Page failed: ", response.statusText)
        }
      })
      .then(json => {
        if (json) {
          this.setState({liveTVPageValue: json.value})
        }
        this.setState({isLiveTVPageLoading: false})
      })
  }

  render = () => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{__html: this.state.liveTVPageValue}} />
        <br/>
        <br/>
        <MoreArticles
          articleType={{name: "News"}}
          articleId={0}
        />
      </div>
    )
  }
}