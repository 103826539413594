import React, {Component} from 'react';
import MoreArticles from './Components/MoreArticles.js';
import HomeEvents from './Components/HomeEvents'
import { Route } from "react-router-dom";
import APIService from '../API.js';
import Moment from 'react-moment';
import 'moment-timezone';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Helpers from "./Helpers";

export default class Article extends Component {

  state = {
    article: {
      articleTypes: [],
      articleImages: [],
      authors: [],
      headline: '',
      story: '',
      updatedAt: Date(),
      createdAt: Date(),
    },

    isArticleLoading: true,
  };

  loadedJWPlayer = false

  articleId = this.props.match.params.id;
  currentPath = this.props.location.pathname;

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    if (
      !this.loadedJWPlayer &&
      !this.state.isArticleLoading &&
      this.state.article.articleVideo &&
      this.state.article.articleVideo.articleVideoType.name === 'JWPlatform'
    ) {
      window.jwplayer("wmfd-article-jwplayer").setup({
        "playlist": `https://content.jwplatform.com/v2/media/${this.state.article.articleVideo.location}`,
        "autostart": "viewable",
        "advertising": {
          "admessage": "This video will resume in xx seconds",
          "adscheduleid": "s1OFE6R4",
          "client": "googima",
          "schedule": [
            {
              "offset": "pre",
              "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
              "type": "linear"
            }
          ]
        }
      });
      this.loadedJWPlayer = true;
    }
  }

  getData = (articleId = this.articleId) => {
    /* API CALL to get article information: Then populate local state */
    this.setState({ isArticleLoading: true, });
    fetch(APIService.serverUrl + '/api/public/article/' + articleId, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("Article Fetch Failed: ", response.status, response.statusText);
        return null
      }
    }) // Checks response and converts to JSON
    .then((json) => {
      if (json) {
        // console.log("Article Fetch Succeded: json - ",json);
        this.setState({
          article: json,
          isArticleLoading: false,
        })
      }
    })
  };

  renderArticleMedia = () => {
    if (!this.state.isArticleLoading) {
      if (this.state.article.articleVideo) {
        switch (this.state.article.articleVideo.articleVideoType.name) {
          case "JWPlatform":
            return (
              <React.Fragment>
                <div id="wmfd-article-jwplayer"></div>
              </React.Fragment>
            );
          default:
            return (
              <div className="videoContainer">
                <iframe id="articleVideoIframe" src={this.state.article.articleVideo.location} frameborder="0" allowfullscreen/>
              </div>
            );
        }
      } else {
        return (
          <OwlCarousel
            items={1}
            loop
            autoplay={true}
            dots={false}
            autoplaySpeed={1000}
            stagePadding={0}
            margin={0}
          >
            {this.state.article.images.map(articleImage => {
              return (
                <div key={articleImage.id} className="item">
                  <div className="cp-post-thumb">
                    {/* Helpers.getOptimizedImage(articleImage.optimizedImages, 900)
                        APIService.serverUrl+articleImage.location */}
                    <img alt={articleImage.id}
                      src={Helpers.getOptimizedImage(articleImage, 900)}
                    />
                  </div>
                </div>
              )
            })}
          </OwlCarousel>
        )
      }
    }
  };

  render() {
    return (

      <div>
        <div className="cp-news-post-excerpt">
          <div className="cp-thumb">
            {this.renderArticleMedia()}
          </div>
          <div className=" cp-post-content">
            <p/>
            <h1
              onClick={() => this.props.history.push(this.currentPath)}
              style={{ color:'#2e6da4', cursor:'pointer' }}
            >
              {this.state.article.headline}
            </h1>

            <ul className="cp-post-tools">
              <li>
                <i className="icon-1"/>
                <Moment format='ddd MMM DD YYYY h:mm a' >{this.state.article.createdAt}</Moment>
              </li>
              <li>
                <i className="icon-2"/>
                {Helpers.authorsCommaSeparated(this.state.article)}
              </li>
              <li>
                <i className="icon-3"/>
                {this.state.article.articleTypes.map((v,i) => (i ? ", ":"") + v.name )}
              </li>
            </ul>

            <div id='widget'>
              <a
                href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com' + this.currentPath + '&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%20' + this.state.article.headline + '&amp;tw_p=tweetbutton&amp;url=https://wmfd.com' + this.currentPath}
                target="_blank" rel="noopener noreferrer"
              >
                <span
                  className="btn"
                  style={{
                    background:"#1DA1F2",
                    borderRadius:"5",
                    color:"#fff",
                    fontSize:"11px",
                    fontWeight:"bold",
                    padding:"2px 5px 1px 5px",
                  }}>
                    <i className="fa fa-twitter" /> Tweet
                </span>
              </a>
              &nbsp;&nbsp;
              <div
                className="fb-share-button"
                data-href={'https://wmfd.com' + this.currentPath}
                data-layout="button_count"
              />
            </div>
          </div>
          <div className="cp-sidebar-content">
            <p>&nbsp;</p>
            <div dangerouslySetInnerHTML={{ __html: this.state.article.story }} />
            <p>&nbsp;</p>
          </div>
        </div>

        {!this.state.isArticleLoading
          ? <MoreArticles
              articleType={ this.state.article.articleTypes.length ? this.state.article.articleTypes[0] : undefined }
              articleId={ this.state.article.id }
              push={this.props.history.push }
            />
          : null
        }

        <Route path="/article" component={HomeEvents} />

      </div>

    )
  }
}
