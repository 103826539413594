import React, { Component } from 'react'
import {Header, List, Segment, Statistic} from "semantic-ui-react";
import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";

class ListIssues extends Component {
  render = () => {
    let fiftyStyle = {
      width: "50%",
      textAlign: "center",
    }
    return (
      <div>
        {this.props.issues.map(issue => {
          let forCount = 0
          let againstCount = 0

          if (issue.totalVoteCount) {
            forCount = issue.forCount
            againstCount = issue.againstCount
          } else {
            forCount = issue.voteCounts.reduce((result, voteCount) => result + voteCount.forCount, 0)
            againstCount = issue.voteCounts.reduce((result, voteCount) => result + voteCount.againstCount, 0)
          }

          let data = [{name: "For", value: forCount}, {name: "Against", value: againstCount}];
          let colors = ["#81BEF7", "#F7BE81"]

          let areNoVotes = data.filter(v => v.value !== 0).length === 0

          if (areNoVotes) {
            data = [{
              name: "Results Coming Soon",
              value: 10
            }];
          }

          return (
            <Segment>
              <Header textAlign='center' size='large' content={issue.name} />
              <ResponsiveContainer height={250}>
                <PieChart width={730} height={250}>
                  <Pie
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    fill="#8884d8"
                    label
                    {...(areNoVotes ? {label: () => "Results Coming Soon"} : {})}
                  >
                    {
                      data.map((entry, index) => <Cell key={`cell-${index}`} fill={areNoVotes ? ["#ddd"] : colors[index % colors.length]} />)
                    }
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div style={{display: "flex", flexWrap: "wrap"}}>
                <div style={fiftyStyle}>
                  <Statistic label='For' value={forCount.toLocaleString()} style={{marginBottom: "20px"}} />
                </div>
                <div style={fiftyStyle}>
                  <Statistic label='Against' value={againstCount.toLocaleString()} style={{marginBottom: "20px"}} />
                </div>
                <div style={fiftyStyle}>
                  <List
                    items={issue.voteCounts.map(voteCount => ({
                      content: voteCount.electionCounty.name + " (" + voteCount.forCount + ")"
                    }))}
                  />
                </div>
                <div style={fiftyStyle}>
                  <List
                    items={issue.voteCounts.map(voteCount => ({
                      content: voteCount.electionCounty.name + " (" + voteCount.againstCount + ")"
                    }))}
                  />
                </div>
              </div>
            </Segment>
          )
        })}
      </div>
    )
  }
}

export default ListIssues
