import React, {Component} from 'react';
import ReactJWPlayer from 'react-jw-player';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';


export default class LatestVideo extends Component {

  state = {
    todaysDate: new moment(),
  }

  renderGoogleAd32() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-32") })
  }

  renderGoogleAd12() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-12") })
  }

  componentDidMount() {
    let cnnVideo = document.createElement('script');
    cnnVideo.setAttribute('language', 'JavaScript');
    cnnVideo.setAttribute('type', 'text/javascript');
    cnnVideo.setAttribute('src', 'https://z.cdn.turner.com/cnn/van/resources/scripts/van-widgets.js');
    document.getElementById('cnn_video').appendChild(cnnVideo);
  }

  render() {
    return (
      <div>

        <div className="widget advertisement">
          <div className="ad-holder">
            <div id='div-gpt-ad-1530459263900-12'>
              { this.renderGoogleAd12() }
          	</div>
          </div>
        </div>

        <div className="cp-news-grid-style-5 m20">
          <div className="section-title orange-border">
            <h2>Latest Video</h2>
            <small>Watch it on WMFD.com .</small>
          </div>
          <div>
            <div className="cp-news-list">
              <ul className="row">
                <li className="col-md-6 col-sm-6">
                  <div className="cp-thumb">
                    <div id='cnn_video' />
                    <div data-cnnvangridwidget data-affiliate="wmfd" data-category="CNN Recommended" data-size="auto" data-autostart="false" data-includeraw="false" data-rows="3">
                    </div>
                  </div>
                </li>
                <li className="col-md-6 col-sm-6">
                  <div className="cp-post-content">
                    <h3>Latest National Video</h3>
                    <ul className="cp-post-tools">
                      <li><i className="icon-1"></i> <Moment format='MM/DD/YYYY' >{this.state.todaysDate}</Moment></li>
                      <li>
                        <span style={{ color: "#32aae1" }}>
                          <i className="icon-9"></i>
                          <i className="icon-9"></i>
                          <i className="icon-9"></i>
                          <i className="icon-9"></i>
                          <i className="icon-10"></i>
                        </span>
                      </li>
                    </ul>
                    <p>Stories from around the world. </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="cp-news-list">
              <ul className="row">
                <li className="col-md-6 col-sm-6">
                  <div className="cp-thumb">

                      <ReactJWPlayer
                        licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
                        playlist='//content.jwplatform.com/v2/media/XxvhU0Hc'
                        playerId='faYPjHWH'
                        playerScript='https://content.jwplatform.com/players/XxvhU0Hc-faYPjHWH.js'
                        aspectRatio='16:9'
                        customProps={{
                          width:'100%',
                          displaytitle: false,
                          advertising: {
                            "admessage": "This video will resume in xx seconds",
                            "adscheduleid": "s1OFE6R4",
                            "client": "googima",
                            "schedule": [
                              {
                                "offset": "pre",
                                "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                                "type": "linear"
                              }
                            ]
                          }
                        }}
                      />

                  </div>
                </li>
                <li className="col-md-6 col-sm-6">
                  <div className="cp-post-content">
                    <h3><a href="https://preview-16bkbirs.jwpapp.com/">High School Soccer in HD</a></h3>
                    <ul className="cp-post-tools">
                      <li className="li2">
                        <span style={{ color: "#32aae1" }}>
                          <i className="icon-9" ></i>
                          <i className="icon-9" ></i>
                          <i className="icon-9" ></i>
                          <i className="icon-9" ></i>
                          <i className="icon-9" ></i>
                        </span>
                      </li>
                    </ul>
                    <p>WMFD Sports now carries LIVE high school soccer! Watch them now in our <a href="https://preview-16bkbirs.jwpapp.com/" target="new">Directions Credit Union Gameroom. </a></p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="cp-news-list">
              <ul className="row">
                <li className="col-md-6 col-sm-6">
                  <div className="cp-thumb">

                      <ReactJWPlayer
                        licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
                        playlist='//content.jwplatform.com/v2/media/1bl9LOTj'
                        playerId='Is5guRF9'
                        playerScript='https://content.jwplatform.com/players/1bl9LOTj-Is5guRF9.js'
                        aspectRatio='16:9'
                        customProps={{
                          width:'100%',
                          displaytitle: false,
                          advertising: {
                            "admessage": "This video will resume in xx seconds",
                            "adscheduleid": "s1OFE6R4",
                            "client": "googima",
                            "schedule": [
                              {
                                "offset": "pre",
                                "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                                "type": "linear"
                              }
                            ]
                          }
                        }}
                      />

                  </div>
                </li>
                <li className="col-md-6 col-sm-6">
                  <div className="cp-post-content">
                    <h3>Catch all of WMFDs local programming in our Videoroom.</h3>
                    <ul className="cp-post-tools">
                      <li>
                        <i className="icon-2"></i> Master Control
                      </li>
                      <li>
                        <span style={{ color: "#32aae1" }}>
                          <i className="icon-9"></i>
                          <i className="icon-9"></i>
                          <i className="icon-9"></i>
                          <i className="icon-9"></i>
                          <i className="icon-9"></i>
                        </span>
                      </li>
                    </ul>
                    <p>Programs like <b><a href="/i-love-my-job">I Love My Job, </a>
                    <a href="/focus-on-north-central-ohio"> Focus, </a>
                    <a href="/senior-living"> Senior Living, </a>
                    <a href="/st-john"> St. Johns </a></b>
                    and many more. </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="widget advertisement">
              <div className="ad-holder">
                <div id='div-gpt-ad-1530459263900-32' >
                  { this.renderGoogleAd32() }
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    )
  }
}

/*

*/
