import React, {Component} from 'react';
import AD from 'react-google-publisher-tag';
import APIService from '../../API.js';
import Moment from 'react-moment';
import 'moment-timezone';
import Helpers from "../Helpers";
import ReactJWPlayer from 'react-jw-player';


export default class MoreArticles extends Component {

  state= {
    ARTICLES: [],
    tagLine: '',
  };

  componentDidMount() {
    this.getMoreArticles();
    this.setTagLine();
  }

  setTagLine = () => {
    let articleTypeName = this.props.articleType.name;
    switch (articleTypeName.toLowerCase()) {
      case 'news' : this.setState({ tagLine: 'Our top News stories from around the area' }); break;
      case 'sports' : this.setState({ tagLine: 'Our top Sports stories from around the area' }); break;
      case 'events' : this.setState({ tagLine: 'Our Events from around the area' }); break;
      case 'obituaries' : this.setState({ tagLine: 'Our postings from area funeral homes' }); break;
      default : this.setState({ tagLine: 'Our top News stories from around the area' }); break;
    };
    // console.log('setTagLine VAR = ' + articleTypeName);
  }

  getMoreArticles = () => {
    /* API CALL to get articel information: Then populate local state */
    // console.log("type name: " + this.props.articleType.name);
    fetch(APIService.serverUrl + '/api/public/article', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "typeName": this.props.articleType.name,
        "excludeArticleId": Number(this.props.articleId)
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("MoreArticles Fetch Failed: ", response.status, response.statusText)
          return null
        }
      }) // Checks response and converts to JSON
      .then((json) => {
        if (json) {
          this.setState({ ARTICLES: json, })
          // console.log("MoreArticles Fetch Succeded")
          // console.log(json)
        } else {
          console.log("MoreArticles Fetch Failed")
        }
      })
  };

  renderArticleMedia = (article) => {
    if (article.articleVideo) {
      switch (article.articleVideo.articleVideoType.name) {
        case "JWPlatform":
          return (
            <ReactJWPlayer
              licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
              playlist={'//content.jwplatform.com/v2/media/' + article.articleVideo.location}
              playerId={'MA'+article.articleVideo.location}
              playerScript={'https://content.jwplatform.com/players/' + article.articleVideo.location + '-lTCrLokv.js'}
              aspectRatio='16:9'
              isAutoPlay={false}
              isLazyLoad={true}
              customProps={{
                width: '100%',
                displaytitle: false,
                advertising: {
                  "admessage": "This video will resume in xx seconds",
                  "adscheduleid": "s1OFE6R4",
                  "client": "googima",
                  "schedule": [
                    {
                      "offset": "pre",
                      "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                      "type": "linear"
                    }
                  ]
                }
              }}
            />
          );
        default:
          return (
            <div className="thumbNailVideoContainer">
              <iframe id="" src={article.articleVideo.location} frameBorder="0"
                      allowFullScreen/>
            </div>
          );
      }
    } else {
      return (
        <img src={Helpers.getOptimizedImage(article.mainImage, 360)} alt={article.headline} />

      )
    }
  };

  render() {

    return (
      <div>

        <div className="cp-news-grid-style-1">
          <div className="section-title blue-border">
            <h2>More Local { this.props.articleType.name }</h2>
            <small>{ this.state.tagLine }</small>
          </div>
        </div>
        <div className="cp-news-grid-style-2 m20">
          <div className="section-title blue-border">
            <div className="cp-news-grid-style-5 m30">
              {this.state.ARTICLES.map( (article,index) => {
                if ( index < 3 ) {
                  return(
                    <div className="cp-news-list" key={article.id}>
                      <ul className="row">
                        <li className="col-md-6 col-sm-6">
                          <div className="cp-thumb">
                            { this.renderArticleMedia(article) }
                          </div>
                        </li>
                        <li className="col-md-6 col-sm-6">
                          <div className="cp-post-content">
                            <h3>
                              <a
                                style={{cursor:'pointer'}}
                                href={Helpers.articleToURLPath(article)}
                              >{article.headline}</a>
                            </h3>
                            <ul className="cp-post-tools">
                              <li><i className="icon-1"></i>
                                <Moment format='ddd MMM DD YYYY h:mm a' >{article.createdAt}</Moment>
                              </li>
                              <li><i className="icon-2"></i> {Helpers.authorsCommaSeparated(article)}</li>
                              <li><i className="icon-3"></i> {article.articleTypes.map((v,i) => (i ? ", ":"") + v.name )}</li>
                            </ul>
                            <p>{article.subHeadline}</p>
                          </div>
                        </li>
                      </ul>
                  </div>
                  )
                } else { return('') }
              })}
            </div>

            <div className="widget advertisement">
              <div  className="ad-holder">
                <div id="ad-div-gpt-ad-1530459263900-12" data-google-query-id="CPr20rCln94CFQvZwAodtJwDKw">
                  <AD path="/20137842/DWMFD_728x90_ROS" />
                </div>
              </div>
            </div>

            <p></p>
            <div className="cp-news-grid-style-1">
              <div className="section-title blue-border">
                <p>&nbsp;</p>
              </div>
            </div>
            <div className="cp-news-grid-style-2 m20">
              <div className="section-title orange-border">
                <ul className="small-grid">
                  {this.state.ARTICLES.map( (article,index) => {
                    if ( index >= 3 ) {
                      return(
                        <li className="col-md-6 col-sm-6" key={article.id}>
                          <div className="small-post">
                            <div className="cp-thumb">
                              <img
                                alt={article.headline}
                                src={Helpers.getOptimizedImage(article.mainImage, 263)}
                              />
                            </div>
                            <div className="cp-post-content">
                              <h3>
                                <a
                                  style={{cursor:'pointer'}}
                                  href={Helpers.articleToURLPath(article)}
                                >{article.headline}</a>
                              </h3>
                              <ul className="cp-post-tools">
                                <li><i className="icon-1"/>
                                  <Moment format='ddd MMM DD YYYY h:mm a' >{article.createdAt}</Moment>
                                </li>
                                <li><i className="icon-2"/> {Helpers.authorsCommaSeparated(article)}</li>
                                <li><i className="icon-3"/> {article.articleTypes.map((v,i) => (i ? ", ":"") + v.name )}</li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      )
                    } else { return('') }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>

    )
  }
}
