import React, {Component} from 'react'
import {Header, Menu} from "semantic-ui-react";
import APIService from "../API";
import ListRaces from "../views/ListRaces";
import ListIssues from "../views/ListIssues";

class ViewElection extends Component {

  state = {
    countyName: "",
    isFetchingElection: false,
    election: {
      races: [],
      issues: []
    },
  }

  electionCountyId = () => this.props.match.params.countyId;
  isStateWide = () => this.electionCountyId() === undefined;

  componentDidMount = () => {
    if (!this.isStateWide()) {
      this.fetchElectionCounty()
    }
    this.fetchElection()
  }

  fetchElectionCounty = () => {
    fetch(APIService.serverUrl + '/api/public/election-county/' + this.electionCountyId(), {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    })
      .then(r => r.ok ? r.json() : null)
      .then(json => {
        if (json) {
          this.setState({countyName: json.name})
        }
      })
  }

  fetchElection = () => {
    this.setState({isFetchingElection: true})
    fetch(APIService.serverUrl + '/api/public/election', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        electionCountyId: Number(this.electionCountyId()),
      })
    })
      .then(r => r.ok ? r.json() : null)
      .then(json => {
        if (json) {
          this.setState({election: json})
        }
        this.setState({isFetchingElection: false})
      })
  }

  render = () => {
    return (
      <div>
        <Header
          as="h1"
          style={{
            color: "rgb(46, 109, 164)",
            fontSize: "32px"
          }}
          content={this.isStateWide() ? "State Wide" : this.state.countyName}
        />
        <Menu pointing secondary color='red' style={{marginBottom: "26px"}}>
          <Menu.Item
            name='races'
            style={{
              fontSize: "20px"
            }}
            as='a'
            active={this.props.match.params.type === 'races'}
            href={'/election/races' + (this.isStateWide() ? "" : ("/" + this.electionCountyId()))}
          />
          <Menu.Item
            name='issues'
            style={{
              fontSize: "20px"
            }}
            as='a'
            active={this.props.match.params.type === 'issues'}
            href={'/election/issues' + (this.isStateWide() ? "" : ("/" + this.electionCountyId()))}
          />
        </Menu>
        {this.props.match.params.type === 'races' ?
          <React.Fragment>
            <ListRaces
              races={this.state.election.races}
            />
            {this.state.isFetchingElection ? null :
              <Menu pointing secondary color='red' style={{marginBottom: "26px"}}>
                <Menu.Item
                  name='See Issues'
                  style={{
                    fontSize: "20px"
                  }}
                  as='a'
                  active
                  href={'/election/issues' + (this.isStateWide() ? "" : ("/" + this.electionCountyId()))}
                />
              </Menu>
            }
          </React.Fragment>
          :
          <React.Fragment>
            <ListIssues
              issues={this.state.election.issues}
            />
            {this.state.isFetchingElection ? null :
              <Menu pointing secondary color='red' style={{marginBottom: "26px"}}>
                <Menu.Item
                  name='See Races'
                  style={{
                    fontSize: "20px"
                  }}
                  as='a'
                  active
                  href={'/election/races' + (this.isStateWide() ? "" : ("/" + this.electionCountyId()))}
                />
              </Menu>
            }
          </React.Fragment>
        }
      </div>
    )
  }
}

export default ViewElection
