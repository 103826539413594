import React, {Component} from 'react';


export default class Text extends Component {

  componentDidMount() {
    window.location.href = "https://my.textcaster.com/ServePopup.aspx?id=1435"
  }

  render() {
    return (
      <div>

      </div>
    )
  }
}
