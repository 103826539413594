import React, {Component} from 'react';
// import ReactJWPlayer from 'react-jw-player';
import MoreArticles from './Components/MoreArticles.js';
// import APIService from "../API";


export default class Privacy extends Component {

  state = {
    article: {
      id: 0,
      articleTypes: [
        { id: 3, name: 'News',}
      ],
    },
    isArticleLoading: false,
  };


  render() {
    return (
      <div className="cp-news-grid-style-1">
        <div className="row">
          <ul className="grid">

          {/* Insert about pages content between these comments */}
            <div className="col-md-12">
              <div className="cp-post-content">
                <h2><a id="privacy" href="#privacy">Online Privacy Policy</a></h2>
                <ul className="cp-post-tools">
                  <li><i className="icon-3"></i> WMFD-TV</li>
                </ul>
              </div>
              <div className="cp-sidebar-content">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/pdfs/privacy-policy_20230208.pdf">
                  Click here to view our Privacy Policy</a>
                <p>&nbsp;</p>
              </div>
            </div>

          {/* Insert about pages content between these comments */}

          </ul>
          <div className="col-md-8" id='widget'>
            <a
              href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com/haring-realty-home-show&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%20haring-realty-home-show&amp;tw_p=tweetbutton&amp;url=https://wmfd.com/haring-realty-home-show'}
              target="_blank" rel="noopener noreferrer"
            >
              <span
                className="btn"
                style={{
                  background:"#1DA1F2",
                  borderRadius:"5",
                  color:"#fff",
                  fontSize:"11px",
                  fontWeight:"bold",
                  padding:"2px 5px 1px 5px",
                }}>
                  <i className="fa fa-twitter" /> Tweet
              </span>
            </a>
            &nbsp;&nbsp;
            <div
              className="fb-share-button"
              data-href={'https://wmfd.com/haring-realty-home-show'}
              data-layout="button_count"
            />
          </div>
        </div>
        <p>&nbsp;</p>

        {!this.state.isArticleLoading
          ? <MoreArticles
              articleType={ this.state.article.articleTypes.length ? this.state.article.articleTypes[0] : undefined }
              articleId={ this.state.article.id }
              push={this.props.history.push }
            />
          : null
        }

      </div>
    )
  }
}

/*



*/
