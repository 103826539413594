import React, {Component} from 'react';
// import ReactJWPlayer from 'react-jw-player';
import MoreArticles from './Components/MoreArticles.js';
// import APIService from "../API";


export default class PublicFileWMFD extends Component {

  state = {
    article: {
      id: 0,
      articleTypes: [
        { id: 3, name: 'News',}
      ],
    },
    isArticleLoading: false,
  };


  render() {
    return (
      <div className="cp-news-grid-style-1">
        <div className="col-md-12 row">
          <ul className="grid">

          {/* Insert about pages content between these comments */}
            <div>
              <div className=" cp-post-content">
                <h2><a id="pf" href="#pf">Public File</a></h2>
                <ul className="cp-post-tools">
                  <li><i className="icon-3"></i> WMFD TV</li>
                </ul>
              </div>
              <div className="cp-sidebar-content">
                <p><a target="_blank" href="https://publicfiles.fcc.gov/tv-profile/wmfd-tv" rel="noopener noreferrer">Click here to access the online Public Inspection File.</a></p>
                <p>Viewers with disabilities can get assistance accessing this station’s FCC Public Inspection File by contacting the station with the information listed below. Questions or concerns relating to the accessibility of the FCC’s online public file system should be directed to the FCC at 888-225-5322, 888-835-5322 (TTY), or fccinfo@fcc.gov<br /></p>
                Station Contact Information:<br />
                WMFD TV<br />
                2900 Park Ave. W.<br />
                Mansfield, Ohio 44906<br />
                419-529-5900<br />
                Fax: 419-529-2319<br />
                comments@wmfd.com<br />
                <p>&nbsp;</p>
              </div>
            </div>

          {/* Insert about pages content between these comments */}

          </ul>
          <div id='widget'>
            <a
              href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com/haring-realty-home-show&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%20haring-realty-home-show&amp;tw_p=tweetbutton&amp;url=https://wmfd.com/haring-realty-home-show'}
              target="_blank" rel="noopener noreferrer"
            >
              <span
                className="btn"
                style={{
                  background:"#1DA1F2",
                  borderRadius:"5",
                  color:"#fff",
                  fontSize:"11px",
                  fontWeight:"bold",
                  padding:"2px 5px 1px 5px",
                }}>
                  <i className="fa fa-twitter" /> Tweet
              </span>
            </a>
            &nbsp;&nbsp;
            <div
              className="fb-share-button"
              data-href={'https://wmfd.com/haring-realty-home-show'}
              data-layout="button_count"
            />
          </div>
          <p>&nbsp;</p>
        </div>

        {!this.state.isArticleLoading
          ? <MoreArticles
              articleType={ this.state.article.articleTypes.length ? this.state.article.articleTypes[0] : undefined }
              articleId={ this.state.article.id }
              push={this.props.history.push }
            />
          : null
        }

      </div>
    )
  }
}

/*



*/
