import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import APIService from "../../API";
import Helpers from "../Helpers";

const titleToURL = (title) => {
  return title.replace(/\s+/g,'-').toLowerCase();
};

export default class HomeSlider extends Component {

  btnColor = 'orange';

  state = {
    featuredArticles: [],
    isLoadingFeaturedArticles: true,
    owlSliderWidth: 0,
  };

  owlSliderRef = (element) => {
    if (typeof element === 'object' && element !== null) {
      this.setState({
        owlSliderWidth: element.container.clientWidth,
      });
    }
  }

  constructor(props) {
    super(props);
    this.getFeaturedArticles();
  }

  getFeaturedArticles = () => {
    // this.setState({ isLoadingFeaturedArticles: true });
    fetch(APIService.serverUrl + '/api/public/article', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        limit: 10,
        isFeatured: true,
      }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        // console.log("Featured Articles: ")
        // console.log(json)
        this.setState({featuredArticles: json});
      } else {
        console.log("Fetch Featured Articles Failed");
      }
      this.setState({isLoadingFeaturedArticles: false});
    });
  };

  changeButtonColor = () => {
    switch (this.btnColor) {
      case 'orange': this.btnColor = 'purple'; break;
      case 'purple': this.btnColor = 'pink'; break;
      case 'pink': this.btnColor = 'gray'; break;
      case 'gray': this.btnColor = 'green'; break;
      case 'green': this.btnColor = 'orange'; break;
      default: this.btnColor = 'orange'; break;
    } return this.btnColor;
  }


  render() {
    let minDesktopWidth = 1200;
    let owlSliderWidth = this.state.owlSliderWidth;

    return (
      <div className="cp-featured-news-slider" >

        {!this.state.isLoadingFeaturedArticles ?
          <OwlCarousel
            ref={this.owlSliderRef}
            items={owlSliderWidth < minDesktopWidth ? 1 : 2}
            loop
            autoplay={true}
            dots={false}
            autoplaySpeed={1000}
            stagePadding={owlSliderWidth < minDesktopWidth ? 0 : 10}
            margin={owlSliderWidth < minDesktopWidth ? 0 : 2}
          >
            {this.state.featuredArticles.map(article => {
              let buttonColor = this.changeButtonColor();
              return (
                <div className="item" key={article.id}>
                  <div className="cp-post-content">
                    <div className="catname">
                      <a
                        className={"btn btn-"+buttonColor+" waves-effect waves-button"}
                        href={"article/" + titleToURL(article.headline) + "/" + article.id}
                      >
                        {article.tag}
                      </a>
                    </div>
                    <h1>
                      <a
                        href={"article/" + titleToURL(article.headline) + "/" + article.id}
                      >
                        {article.headline}
                      </a>
                    </h1>
                  </div>
                  <div className="cp-post-thumb">
                    {/* APIService.serverUrl+'/images/'+article.mainImage.id+'/'+article.mainImage.filename*/}
                    <img
                      src={Helpers.getOptimizedImage(article.mainImage, 900)}
                      alt={article.headline}
                    />
                  </div>
                </div>
              )
            })}
          </OwlCarousel>
          : null
        }

      </div>
    )
  }
}

/*

*/
