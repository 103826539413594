const APIService = require("../API");

const Helpers = {

  articleToURLPath: (article) => {
    let safeArticle = article;
    if (safeArticle === undefined || safeArticle === null) {
      safeArticle = { headline: "" };
    }
    return "/article/" + safeArticle.headline.replace(/\s+/g,'-').toLowerCase() + "/" + safeArticle.id;
  },

  articleImageURL: (articleImage, size = 'filename') => {
    let safeArticleImage = articleImage;
    if (safeArticleImage === undefined || safeArticleImage === null) safeArticleImage = {};
    return APIService.serverUrl + '/articleimages/' + safeArticleImage.id + '/' + safeArticleImage[size];
  },

  getOptimizedImage: (mainImage = {}, size) => { // 900, 750, 360, 263
    let optimizedImages = mainImage.optimizedImages ? mainImage.optimizedImages : [];
    for (let optimizedImage of optimizedImages) {
      if (optimizedImage.width === size) {
        return APIService.serverUrl + optimizedImage.location
      }
    }
  },

  authorsCommaSeparated(article) {
    let safeAuthors = article.authors;
    if (safeAuthors === undefined || safeAuthors === null) safeAuthors = [];
    return article.shouldShowGenericAuthor ? 'WMFD Staff' : safeAuthors.map((a,i) => (i ? ', ':'') + a.name);
  },
};

module.exports = Helpers;
