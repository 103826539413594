import React, {Component} from 'react'
import APIService from "../API";
import Moment from "react-moment";
import moment from "moment";
import ClosingDetails from "./Components/ClosingDetails";

export default class ClosingList extends Component {

  state = {
    schoolClosings: [],
    businessClosings: [],
    churchClosings: [],
    otherClosings: [],
    unsavedAgencyClosings: [],
  };

  closingId = this.props.match.params.closingId;

  componentDidMount() {
    this.fetchClosings("school");
    this.fetchClosings("business");
    this.fetchClosings("church");
    this.fetchClosings("other");
    this.fetchUnsavedAgencyClosings();
  }

  fetchClosings = (agencyType) => {
    fetch(APIService.serverUrl + "/api/public/closing", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({ agencyTypeName: agencyType, }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        let key = agencyType + "Closings";
        this.setState({[key]:json});
      } else {
        console.log("Fetch Closings for agency type " + agencyType + " failed");
      }
    })
  };

  fetchUnsavedAgencyClosings = () => {
    fetch(APIService.serverUrl + "/api/public/closing", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        unsavedAgencies: true,
      }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({unsavedAgencyClosings:json});
      } else {
        console.log("Fetch Unsaved Agency Closings failed");
      }
    })
  };

  renderGoogleAd6() {
    window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1530459263900-6'); })
  }

  renderGoogleAd16() {
    window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1530459263900-16'); })
  }

  render = () => {

    let isViewingClosing = this.closingId !== undefined;

    return (

      <div className="cp-news-post-excerpt">
        <div className="cp-thumb"></div>
        <div className=" cp-post-content">
          <h2><a href="# ">Closings and Delays</a></h2>
          <ul className="cp-post-tools">
            <div className="widget advertisement">
              {isViewingClosing ? null :
                <div className="ad-holder">
                  <div id="div-gpt-ad-1530459263900-6">
                    {this.renderGoogleAd6()}
                  </div>
                  <p></p>
                  <div id="div-gpt-ad-1530459263900-16">
                    {this.renderGoogleAd16()}
                  </div>
                </div>
              }
            </div>
            <p>&nbsp;</p>
          </ul>
        </div>
        <div className="section-title blue-border">

          <h2>School Closings and Delays</h2>
          <small>Announcements for area schools. </small>
        </div>

        {this.state.schoolClosings.map(closing => {
          let color = 'red' // (closing.closingType.name === 'Closed') ? 'red' : 'purple';
          return ([
            <div key={closing.id} className={"school-title-"+ color +" "+ color +"-border"}>
              <h2>
                <a
                  style={{cursor: "pointer"}}
                  href={"/closings/" + closing.id}
                > {closing.agency.name}
                </a>
              </h2>
              {/*<smallupdate> {closing.closingType.name} </smallupdate>*/}
              <smallupdate>
                {moment(closing.createdAt).isSame(moment(closing.updatedAt)) ?
                  "Posted: "
                  :
                  <font color='#ff0000'>Updated: </font>
                }
                {moment(closing.updatedAt).format('M/D/YYYY - h:mm a')}
              </smallupdate>
              <small>
                <a
                  style={{cursor: "pointer"}}
                  href={"/closings/" + closing.id}
                > Details - click here</a>
              </small>
            </div>,

          ])
        })}

        { !this.state.schoolClosings[0] ?
            <div className="school-title-green greene-border">
              <h2>None Reported At This Time - Be sure to check below</h2>
            </div>
          :
            '' }
        <div><p>&nbsp;</p></div>
        <div><p>&nbsp;</p></div>
        <div className="section-title blue-border">
          <h2>Business Closings and Delays</h2>
          <small>Announcements for area businesses. </small>
        </div>

        {this.state.businessClosings.map(closing => {
          let color = 'red' // (closing.closingType.name === 'Closed') ? 'red' : 'purple';
          return ([
            <div key={closing.id} className={"school-title-"+ color +" "+ color +"-border"}>
              <h2>
                <a
                  href={"/closings/" + closing.id}
                > {closing.agency.name}</a>
              </h2>
              {/*<smallupdate> {closing.closingType.name} </smallupdate>*/}
              <smallupdate>
                {moment(closing.createdAt).isSame(moment(closing.updatedAt)) ?
                  "Posted: "
                  :
                  <font color='#ff0000'>Updated: </font>
                }
                {moment(closing.updatedAt).format('M/D/YYYY - h:mm a')}
              </smallupdate>
              <a
                href={"/closings/" + closing.id}
              > Details - click here</a>
            </div>,
            <p/>
          ])
        })}

        { !this.state.businessClosings[0] ?
            <div className="school-title-green greene-border">
              <h2>None Reported At This Time - Be sure to check below</h2>
            </div>
          :
            '' }

        <div><p>&nbsp;</p></div>
        <div><p>&nbsp;</p></div>
        <div className="section-title blue-border">
          <h2>Church Closings and Delays</h2>
          <small>Announcements for area churches. </small>
        </div>

        {this.state.churchClosings.map(closing => {
          let color = 'red' // (closing.closingType.name === 'Closed') ? 'red' : 'purple';
          return ([
            <div key={closing.id} className={"school-title-"+ color +" "+ color +"-border"}>
              <h2>
                <a
                  href={"/closings/" + closing.id}
                > {closing.agency.name}</a>
              </h2>
              {/*<smallupdate> {closing.closingType.name} </smallupdate>*/}
              <smallupdate>
                {moment(closing.createdAt).isSame(moment(closing.updatedAt)) ?
                  "Posted: "
                  :
                  <font color='#ff0000'>Updated: </font>
                }
                {moment(closing.updatedAt).format('M/D/YYYY - h:mm a')}
              </smallupdate>
              <a
                href={"/closings/" + closing.id}
              > Details - click here</a>
            </div>,
            <p/>
          ])
        })}

        { !this.state.churchClosings[0] ?
            <div className="school-title-green greene-border">
              <h2>None Reported At This Time - Be sure to check below</h2>
            </div>
          :
            '' }

        <div><p>&nbsp;</p></div>
        <div><p>&nbsp;</p></div>
        <div className="section-title blue-border">
          <h2>Other Closings and Delays</h2>
          <small>Other Announcements </small>
        </div>


        {this.state.otherClosings.map(closing => {
          let color = 'red' // (closing.closingType.name === 'Closed') ? 'red' : 'purple';
          return ([
            <div key={closing.id} className={"school-title-"+ color +" "+ color +"-border"}>
              <h2>
                <a
                  href={"/closings/" + closing.id}
                > {closing.agency.name}</a>
              </h2>
              {/*<smallupdate> {closing.closingType.name} </smallupdate>*/}
              <smallupdate>
                {moment(closing.createdAt).isSame(moment(closing.updatedAt)) ?
                  "Posted: "
                  :
                  <font color='#ff0000'>Updated: </font>
                }
                {moment(closing.updatedAt).format('M/D/YYYY - h:mm a')}
              </smallupdate>
              <a
                href={"/closings/" + closing.id}
              > Details - click here</a>
            </div>,
            <p/>
          ])
        })}

        {this.state.unsavedAgencyClosings.map(closing => {
          let color = 'red' // (closing.closingType.name === 'Closed') ? 'red' : 'purple';
          return ([
            <div key={closing.id} className={"school-title-"+ color +" "+ color +"-border"}>
              <h2>
                <a
                  href={"/closings/" + closing.id}
                > {closing.unsavedAgencyName}</a>
              </h2>
              {/*<smallupdate> {closing.closingType.name} </smallupdate>*/}
              <smallupdate>
                {moment(closing.createdAt).isSame(moment(closing.updatedAt)) ?
                  "Posted: "
                  :
                  <font color='#ff0000'>Updated: </font>
                }
                {moment(closing.updatedAt).format('M/D/YYYY - h:mm a')}
              </smallupdate>
              <a
                href={"/closings/" + closing.id}
              > Details - click here</a>
            </div>,
            <p/>
          ])
        })}

        { (!this.state.otherClosings[0] && !this.state.unsavedAgencyClosings[0]) ?
            <div className="school-title-green greene-border">
              <h2>None Reported At This Time</h2>
            </div>
          :
            '' }

        <div><p>&nbsp;</p></div>
        <div><p>&nbsp;</p></div>
          <div className="widget advertisement">
            <div className="ad-holder">
              <p> </p>
              {isViewingClosing ? null :
                <div id="div-gpt-ad-1530459263900-3">
                  <script>
                    {/*{window.googletag.cmd.push(function() { googletag.display('div-gpt-ad-1530459263900-3'); })}*/}
                  </script>
                </div>
              }
            </div>
          </div>
        {isViewingClosing ?
          <ClosingDetails
            closingId={this.closingId}
          />
          : null
        }
      </div>


    )
  }
}
