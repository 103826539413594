import React, {Component} from 'react';
import APIService from "../API";

export default class SnowTrailsTicket extends Component {

  state = {
    snowTrailsTicketState: null,
  };

  componentDidMount() {
    this.fetchLayoutResource('snowTrailsTicket');
  }

  fetchLayoutResource = (type) => {
    fetch(APIService.serverUrl + "/api/public/layoutResource", {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        reference: type,
      })
    })
    .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({ snowTrailsTicketState: json.value })
      } else {
        console.log("Fetch "+type+" Resource failed")
      }
    })
  };


  render() {
    return (

      <div class="cp-news-grid-style-1">
        <div class="section-title blue-border">
          <h2>Snow Trails Ticket Giveaway</h2>
          <small>Mix 106.1 FM Snow Trails Ticket Giveaway</small>
        </div>
        <div class="row">
          <ul class="grid">
            <p align="center">
              <div dangerouslySetInnerHTML={{__html:
                `<script language="javascript" type="text/javascript">
                    <!--
                    var browName = navigator.appName;
                    var SiteID = 1;
                    var ZoneID = 127;
                    var browDateTime = (new Date()).getTime();
                    var wd = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                    if (browName == 'Netscape') {
                        document.write('<s' + 'cript lang' + 'uage="jav' + 'ascript" src="https://wmfd.com/ads/a.aspx?ZoneID=' + ZoneID + '&amp;Task=Get&amp;IFR=False&amp;Browser=NETSCAPE4&amp;PageID=35585&amp;SiteID=' + SiteID + '&amp;Random=' + browDateTime + '&amp;wd=' + wd + '">');
                        document.write('</' + 'scr' + 'ipt>');
                    }
                    if (browName != 'Netscape') {
                        document.write('<s' + 'cript lang' + 'uage="jav' + 'ascript" src="https://wmfd.com/ads/a.aspx?ZoneID=' + ZoneID + '&amp;Task=Get&amp;IFR=False&amp;PageID=35585&amp;SiteID=' + SiteID + '&amp;Random=' + browDateTime + '&amp;wd=' + wd + '">');
                        document.write('</' + 'scr' + 'ipt>');
                    }
                    // -->
                </script>`
              }} />
              <div dangerouslySetInnerHTML={{__html:
                `<script language="javascript" src="https://wmfd.com/ads/a.aspx?ZoneID=127&amp;Task=Get&amp;IFR=False&amp;Browser=NETSCAPE4&amp;PageID=35585&amp;SiteID=1&amp;Random=1550084317410&amp;wd=1440">
                </script>`
              }} />
            </p>
            <div class="cp-sidebar-content">
                <p>&nbsp;</p>
            </div>

            <div dangerouslySetInnerHTML={{__html: this.state.snowTrailsTicketState}} />

            <p align="center">
              <div dangerouslySetInnerHTML={{__html:
                `<script language="javascript" src="https://wmfd.com/ads/a.aspx?ZoneID=127&amp;Task=Get&amp;IFR=False&amp;Browser=NETSCAPE4&amp;PageID=35585&amp;SiteID=1&amp;Random=1550084317547&amp;wd=1440">
                </script>`
              }} />
            </p>
          </ul>
        </div>
      </div>

    )
  }
}

/*

*/
