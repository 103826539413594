import React, {Component} from 'react';
import ReactJWPlayer from 'react-jw-player';
import APIService from "../../API";
import Helpers from "../Helpers";
import Moment from "react-moment";


export default class HomeSports extends Component {

  state = {
    sportArticles: [],
    isSportArticlesLoading: true,
  };

  componentDidMount() {
    this.fetchSportArticles();
  }

  fetchSportArticles = () => {
    this.setState({isSportArticlesLoading: true});
    fetch(APIService.serverUrl + '/api/public/article', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        typeName: "Sports",
      }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({sportArticles: json})
      } else {
        console.log("Fetch Sport Articles failed")
      }
      this.setState({isSportArticlesLoading: false})
    })
  };

  renderGoogleAd30() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-30") })
  }
  renderGoogleAd31() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-31") })
  }

  renderArticleMedia = (article) => {
    if (article.articleVideo) {
      switch (article.articleVideo.articleVideoType.name) {
        case "JWPlatform":
          return (
            <ReactJWPlayer
              licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
              playlist={'//content.jwplatform.com/v2/media/' + article.articleVideo.location}
              playerId={'HS'+article.articleVideo.location}
              playerScript={'https://content.jwplatform.com/players/' + article.articleVideo.location + '-lTCrLokv.js'}
              aspectRatio='16:9'
              isAutoPlay={false}
              customProps={{
                width: '100%',
                displaytitle: false,
                advertising: {
                  "admessage": "This video will resume in xx seconds",
                  "adscheduleid": "s1OFE6R4",
                  "client": "googima",
                  "schedule": [
                    {
                      "offset": "pre",
                      "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                      "type": "linear"
                    }
                  ]
                }
              }}
            />
          );
        default:
          return (
            <div dangerouslySetInnerHTML={{__html: article.articleVideo.location}} />
          );
      }
    } else {
      return (
        <img src={Helpers.getOptimizedImage(article.mainImage, 360)} alt={article.headline} />

      )
    }
  };


  render() {

    return (

      <div>

        <div className="cp-news-grid-style-4 m50">
          <div className="section-title pink-border">
            <h2>Sports</h2>
            <small>High School Football, Basketball, Soccer and more</small>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.state.sportArticles.length === 0 ? null : (
                <div className="cp-news-post-excerpt">
                  <div className="cp-thumb">

                    { this.renderArticleMedia(this.state.sportArticles[0]) }

                  </div>
                  <div className="cp-post-content">
                    <h3>
                      <a
                        href={Helpers.articleToURLPath(this.state.sportArticles[0])}
                      >{this.state.sportArticles[0].headline}
                      </a>
                    </h3>
                    <small>
                      {this.state.sportArticles[0].subHeadline}
                    </small>
                    <ul className="cp-post-tools">
                      <li>
                        <i className="icon-1"/>
                        <Moment format='ddd MMM DD YYYY h:mm a' >{this.state.sportArticles[0].createdAt}</Moment>
                      </li>
                      <li>
                        <i className="icon-2"/>
                        {Helpers.authorsCommaSeparated(this.state.sportArticles[0])}
                      </li>
                      <li><i className="icon-3"/> Sports</li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div  className="col-md-9">
              <div  className="row">
                <ul  className="grid">
                  {this.state.sportArticles.map((article, index) => {
                    return index < 1 ? null : (
                      <li className="col-md-6 col-sm-6" key={article.id}>
                        <div className="cp-post">
                          <div className="cp-thumb">

                            { this.renderArticleMedia(article) }

                          </div>
                          <div className="cp-post-content">
                            <h3>
                              <a
                                href={Helpers.articleToURLPath(article)}
                              >{article.headline}
                              </a>
                            </h3>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>

            <div  className="col-md-3">
              <div  className="v-ad">
                <p align="center">
                </p>
                <div id="div-gpt-ad-1530459263900-31" data-google-query-id="CLKk2vGr2d4CFVZXAQod5SgD3w">
                  { this.renderGoogleAd31() }
                </div>
                <p align="center"></p>
                <div id='div-gpt-ad-1530459263900-30' >
                  { this.renderGoogleAd30() }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
