import React, {Component} from "react"
import APIService from "../../API";
import Moment from "react-moment";
import moment from "moment";
import {Button, Modal} from "semantic-ui-react";

export default class ClosingDetails extends Component {

  closingId = this.props.closingId;

  state = {
    closing: {
      closingType: {},
      noAM: false,
      noPM: false,
      startDate: new moment().toDate(),
      notes: '',
      webNotes: '',
    },
  };

  componentDidMount() {
    this.fetchClosing();
  }

  fetchClosing = () => {
    fetch(APIService.serverUrl + '/api/public/closing/' + this.closingId, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({closing: json})
      } else {
        console.log("Fetch closing failed")
      }
    })
  };

  renderGoogleAd6() {
    window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1530459263900-6'); })
  }

  renderGoogleAd16() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-16") })
  }

  renderGoogleAd3() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-3") })
  }


  render = () => {
    let { closing } = this.state;

    return (
      <Modal open size='large'>
        <Modal.Content>
          <Button content="Close" floated="right" href="/closings" />
          <div align="center">
            <table id="table2" width="100%" border="0">
              <tbody>
                <tr>
                  <td colSpan="2">
                    <p align="center"></p>
                    <div id="div-gpt-ad-1530459263900-6">
                      { this.renderGoogleAd6() }
                    </div>
                    <p></p>
                    <p align="center"></p>
                    <div id="div-gpt-ad-1530459263900-16">
                      { this.renderGoogleAd16() }
                    </div>
                    <p></p>
                  </td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                  <td colSpan="2" bgcolor="#000080">
                    <p align="center"><b><font color="#FFFFFF">Details</font></b></p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" width="83%"><b></b><p align="center"><b>{closing.agency ? closing.agency.name : closing.unsavedAgencyName}</b></p></td>
                </tr>
                <tr>
                  <td width="44%">
                    <b>Effective Until:</b> <Moment format='M/D/YYYY - h:mm a' >{moment(closing.endDate)}</Moment>
                  </td>
                  <td width="38%">
                    <b>Status</b>: <font color="#FF0000"><b> {closing.closingType.name}{closing.noAM ? ', No AM' : null}{closing.noPM ? ', No PM' : null} </b></font>
                  </td>
                </tr>
                <tr>
                  <td width="44%">
                    {moment(closing.createdAt).isSame(moment(closing.updatedAt)) ?
                      <b>Posted: </b> : <b><font color='#FF0000'>Updated: </font></b>
                    }
                    &nbsp;{moment(closing.updatedAt).format('M/D/YYYY - h:mm a')}
                  </td>
                  <td width="38%"><p></p></td>
                </tr>
                <tr>
                  <td colSpan="2" width="83%"><b>Details:</b> { (closing.webNotes && closing.webNotes!=='') ? closing.webNotes : closing.notes } </td>
                </tr>
                <tr>
                  <td colSpan="2" width="83%"> </td>
                </tr>
                <tr>
                  <td colSpan="2" width="83%" height="23"> </td>
                </tr>
                <tr>
                  <td colSpan="2" width="83%" height="20" bgcolor="#000080">
                    <b><font color="#FFFFFF">Information on this page is copyrighted and may not be published or rebroadcast without the express written consent of Mid-State Television. </font></b>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" width="83%">
                    <p align="center">
                    </p>
                    <div id="div-gpt-ad-1530459263900-3">
                      { this.renderGoogleAd3() }
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <table id="table3" width="100%" border="0">
            <tbody>
              <tr>
                <td bgcolor="#FFFFFF">
                  <font color="#FFFFFF">&nbsp; </font>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p><font color="#FFFFFF">&nbsp;</font></p>
                  <p><font color="#FFFFFF">&nbsp;</font></p>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Content>
      </Modal>
    )
  }
}
