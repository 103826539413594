import React, {Component} from 'react';
import LatestVideo from './Components/LatestVideo.js';

export default class CoffeeTalk extends Component {
  
  render() {
    return (
      <div className="cp-news-grid-style-1">
        <div className="section-title blue-border">
          <h2>Coffee Talk</h2>
          <small>WMFD Online in HD on your schedule!</small>
        </div>
        <div className="col-md-12 row">
          <iframe
            name="custom_listbar"
            id="custom_listbar"
            scrolling="yes"
            src='/coffeetalk.html'
            style={{"width": "768px", "height": "650px"}}
            title="CoffeeTalk"
            frameBorder="0"
          ></iframe>
          
          <p>&nbsp;</p>
          
          <div id='widget'>
            <a
              href={'https://twitter.com/intent/tweet?original_referer=https://wmfd.com/coffee-talk&amp;ref_src=twsrc%5Etfw&amp;text=WMFD.com%20-%coffee-talk&amp;tw_p=tweetbutton&amp;url=https://wmfd.com/coffee-talk'}
              target="_blank" rel="noopener noreferrer"
            >
              <span
                className="btn"
                style={{
                  background:"#1DA1F2",
                  borderRadius:"5",
                  color:"#fff",
                  fontSize:"11px",
                  fontWeight:"bold",
                  padding:"2px 5px 1px 5px",
                }}>
                  <i className="fa fa-twitter" /> Tweet
              </span>
            </a>
            &nbsp;&nbsp;
            <div
              className="fb-share-button"
              data-href={'https://wmfd.com/coffee-talk'}
              data-layout="button_count"
            />
          </div>
          <p>&nbsp;</p>
        </div>
        
        {/*<LatestVideo />*/}
      
      </div>
    )
  }
}

/*

*/
